<template>
	<div>
	    <AppTopbar
	        title="International KYC"
	        buttonText="New Order"
	        buttonIconClass="icon-add"
	        buttonLink="new"
	    />
		<div class="container-fluid pt-md-100 pt-70">
			<div class="card col-md-6 m-auto">

				<div class="card__title">
					<span class="fs18 fw500  d-block">
						International KYC Form 
					</span>
				</div>

				<div class="card__content">
					<!-- <span class="fs14 text-gray-800 pb-10 d-block">
						Kindly provide the below mentioned documents as applicable.
					</span> -->

					<div v-if="isBusy" class="text-center">
						<b-card>
							<b-skeleton animation="wave" class="mb-10" width="85%"></b-skeleton>
							<b-skeleton animation="wave" class="mb-10" width="55%"></b-skeleton>
							<b-skeleton animation="wave" class="mb-10" width="70%"></b-skeleton>
						</b-card>
					</div>
					<b-form v-else @submit.prevent="kycSubmit" class="row pt-10">

						<div class="col-md-12 col-12">
				            <b-form-group v-slot="{ ariaDescribedby }">
								<b-form-radio-group
									id="radio-group-2"
									v-model="form.customType"
									:aria-describedby="ariaDescribedby"
									name="radio-sub-component"
									:disabled="user.isAdApproved == 1 || user.isAdApproved == 2"
									required
								>
									<b-form-radio value="CSB IV" :disabled="$store.getters.global.csbRedirect || $store.getters.global.csbRejected">CSB IV</b-form-radio>
									<b-form-radio value="CSB V">CSB V</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>

						<div class="col-md-6 col-9">
				            <b-form-group id="panCardId" label="PAN:" label-for="panCard" class="wsnw">
				              	<b-form-input v-model="form.panNo" :disabled="user.isIntlPanApproved == 1 || user.isIntlPanApproved == 2 || user.panIsApproved == 1 || user.panIsApproved == 2" id="panCard" type="text" placeholder="Enter your pan card number" required></b-form-input>
				            </b-form-group>
						</div>

						<div class="col-md-6 col-9 d-flex align-items-center mt-10">
							<b-form-file ref="panfile" accept=".jpg, .png, .jpeg, .pdf" @input="validate(form.panImage,'pan')" v-model="form.panImage" class="d-none" plain>
							</b-form-file>

							<b-button :disabled="user.isIntlPanApproved == 1 || user.isIntlPanApproved == 2" @click="$refs.panfile.$el.click();" class="w-100" variant="tertiary">
								{{ user.isIntlPanApproved == 1 ? 'PAN Approved' : user.isIntlPanApproved == 2 ? 'Pending Approval' : form.panImage ? form.panImage.name : form.customType=='CSB IV' ? 'Choose File *' : 'Choose File'  }}
							</b-button>
						</div>

						<div class="col-md-6 col-9">
				            <b-form-group id="length-field" label="GSTN:" label-for="gstn" class="wsnw">
				              <b-input v-model="form.gstno" :disabled="user.isIntlGstnApproved == 1 || user.isIntlGstnApproved == 2 || user.isGstnApproved == 1 || user.isGstnApproved == 2" required id="gstn" type="text" placeholder="Enter your GST number"></b-input>
				            </b-form-group>
						</div>

						<div class="col-md-6 col-9 d-flex align-items-center mt-10">
							<b-form-file ref="gstfile" accept=".jpg, .png, .jpeg, .pdf" @input="validate(form.gstnImage,'gst')" v-model="form.gstnImage" class="d-none" plain>
							</b-form-file>

							<b-button :disabled="user.isIntlGstnApproved == 1 || user.isIntlGstnApproved == 2" @click="$refs.gstfile.$el.click();" class="w-100" variant="tertiary">
								{{user.isIntlGstnApproved == 1 ? 'GST Approved' : user.isIntlGstnApproved == 2 ? 'Pending Approval' : form.gstnImage ? form.gstnImage.name : form.customType=='CSB V' ? 'Choose File *' : 'Choose File' }}
							</b-button>
						</div>
						
						<div class="col-md-6 col-9">
				            <b-form-group id="length-field" label="IEC No:" label-for="iecNo" class="wsnw">
				              <b-form-input :disabled="user.isIecApproved == 1 || user.isIecApproved == 2" maxlength="10" @keydown.space.prevent pattern="[a-zA-Z0-9]+" oninvalid="this.setCustomValidity('IEC number has to be Alphanumeric and of 10 Characters')"
                      		oninput="this.setCustomValidity('')" minlength="10" v-model="form.iecNo" id="iecNo" type="text" placeholder="Enter your IEC number" ></b-form-input>
				            </b-form-group>
						</div>

						<div class="col-md-6 col-9 d-flex align-items-center mt-10">
							<b-form-file ref="iecfile"  accept=".jpg, .png, .jpeg, .pdf" @input="validate(form.iecImage,'iec')" v-model="form.iecImage" class="d-none" plain>
							</b-form-file>

							<b-button :disabled="user.isIecApproved == 1 || user.isIecApproved == 2" @click="$refs.iecfile.$el.click();" class="w-100" variant="tertiary">
								{{user.isIecApproved == 1 ? 'IEC Approved' : user.isIecApproved == 2 ? 'Pending Approval' : form.iecImage ? form.iecImage.name : form.customType=='CSB V' ? 'Choose File *' : 'Choose File' }}
							</b-button>
						</div>


						<div class="col-md-12" style="max-width: 97%;border-bottom: 2px solid #dedada;margin: 1rem 0rem 1rem 0.5rem">
						</div>

						<div class="col-md-6 col-9">
				            <b-form-group id="length-field" label="Authorization Letter:" label-for="authfile" class="wsnw">
								<b-button :disabled="(user.authLetterApproved == 1 || user.authLetterApproved == 2)" id="authfile" @click="downloadToast" class="mr-20 btn-block"  variant="primary">
								<i class="icon-download mr-11"></i> Download Template
								</b-button>
				            	<b-form-file ref="authfile" accept=".pdf, .doc, .docx" @input="validate(form.authfile,'auth')" v-model="form.authfile" class="d-none" plain>
								</b-form-file>

								<b-button :disabled="user.authLetterApproved == 1 || user.authLetterApproved == 2" @click="$refs.authfile.$el.click();" class="w-100" variant="tertiary">
									{{user.authLetterApproved == 1  ? 'Authorization Letter Approved' : user.authLetterApproved == 2  ? 'Pending Approval' : form.authfile ? form.authfile.name : 'Choose File *' }}
								</b-button>
				            </b-form-group>
						</div>

						<div class="col-12 pt-2 fs12 text-gray-600"><strong>NOTE : Please put it on the Company's letter head duly stamped and signed by the authorized signatory.</strong> </div>

						<div class="col-md-6 col-9 pt-15">
							<b-form-group id="date-field" label="LUT Expiry Date:" label-for="date">
								<date-picker
									v-model="form.lutExpiryDate"
									placeholder="yyyy-mm-dd"
									type="date"
									:disabled-date="(date) => date < new Date()"
									prefix-class="xmx"
									:disabled="user.louApproved == 1 || user.louApproved == 2"
									required
								>
									<i slot="icon-calendar" class="icon-calendar"></i>
								</date-picker>
							</b-form-group>
						</div>
						<div class="col-md-6 col-9 pt-15">
				            <b-form-group id="length-field" label="Letter of Undertaking:" label-for="iecNo" class="wsnw">
				            	<b-form-file ref="loufile" accept=".pdf, .doc, .docx" @input="validate(form.loufile,'lou')" v-model="form.loufile" class="d-none" plain>
							</b-form-file>

							<b-button :disabled="user.louApproved == 1 || user.louApproved == 2" @click="$refs.loufile.$el.click();" class="w-100" variant="tertiary">
								{{user.louApproved == 1  ? 'Letter of Undertaking Approved' : user.louApproved == 2  ? 'Pending Approval' : form.loufile ? form.loufile.name : 'Choose File' }}
							</b-button>
				            </b-form-group>
						</div>

						<div class="col-md-6 col-9">
				            <b-form-group id="length-field" label="AD Code:" label-for="adcode_intl" class="wsnw">
				              <b-input @keydown.space.prevent v-model="form.adCode" :disabled="user.isAdApproved == 1 || user.isAdApproved == 2" id="adcode_intl" minlength="7" maxlength="15" type="text" placeholder="Enter AD Code"></b-input>
				            </b-form-group>
						</div>

						

						<div class="col-md-6 col-9 pt-18">
				            <b-form-group id="length-field" label=" " label-for="adcode_bank_intl" class="wsnw">
				              <b-input @keydown.space.prevent v-model="form.adbankaccount"
                      		  :disabled="user.isAdApproved == 1 || user.isAdApproved == 2" id="adcode_bank_intl" minlength="9" maxlength="18" pattern="[0-9]+" type="text" title="Enter the bank account number linked with AD code." placeholder="Enter Bank A/c No."></b-input>
				            </b-form-group>
						</div>

						<div class="col-md-6 col-9"></div>


						<div class="col-md-6 col-9 d-flex align-items-center">
							<b-form-file ref="adcode" accept=".pdf, .doc, .docx" @input="validate(form.adFile,'adCode')" v-model="form.adFile" class="d-none" plain>
							</b-form-file>

							<b-button :disabled="user.isAdApproved == 1 || user.isAdApproved == 2" @click="$refs.adcode.$el.click();" class="w-100" variant="tertiary">
								{{user.isAdApproved == 1 ? 'AD Code Approved' : user.isAdApproved == 2 ? 'Pending Approval' : form.adFile ? form.adFile.name : form.customType=='CSB V' ? 'Choose File *' : 'Choose File' }}
							</b-button>
						</div>



						<b-form-checkbox v-show="!(user.isIntlPanApproved && user.isIntlGstnApproved && user.isIecApproved && user.authLetterApproved && user.louApproved && user.isAdApproved)" v-model="form.kycAgree" id="agreeCheckBox" name="checkbox-2" value="Checked" unchecked-value="Not Checked" class="col-12 text-gray-800 ml-10 pt-18">By clicking Submit, you agree to Shyplite's 
						<b-link class="fs14" :href="user.seller && user.seller.agreementData.agreement ? user.seller.agreementData.agreement : 'https://shyplitepublicassets.s3.ap-southeast-1.amazonaws.com/intltnc1.pdf' " target="_blank"> International T&C </b-link></b-form-checkbox>

						<div v-if="fileerror.length" class="fs12 ml-10 text-red mt-20 w-100">{{ fileerror }}</div>

						<div class="d-flex mt-md-24 col">
	  						<b-button v-show="!(user.isIntlPanApproved && user.isIntlGstnApproved && user.isIecApproved && user.authLetterApproved && user.louApproved && user.isAdApproved)" variant="primary" :disabled="(form.kycAgree == 'Checked' ? false : true) || submitting || (fileerror.length ? true : false) || ((form.customType=='CSB IV' && form.panImage && form.authfile) ? false: true) && ((form.customType=='CSB V' && form.gstnImage && form.gstno && form.iecImage && form.iecNo && form.authfile && form.adFile && form.adCode && form.adbankaccount) ? false: true)" type="submit" size="lg" class="w-sm-100">
	  						Submit<b-spinner v-if="submitting" class="ml-10"></b-spinner></b-button>
						</div>

					</b-form>
				
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import DatePicker from "vue2-datepicker";
	import AppTopbar from "./layout/AppTopbar.vue";

	export default {
		name: "intlkyc",
		title: "Shyplite - International KYC",
		components: {
			AppTopbar,
			DatePicker
		},
		data() {
			return {
				isBusy: true,
				form: {
					kycAgree:true,
					customType:''
				},
				browseText: "Browse File",
				fileerror: "",
				freezePan: false,
				freezeGst: false,
				submitting: false,
				user:{}
			}
		},
		created() {
			this.intlGet();
		},

		methods: {

			downloadToast() {
				let samplePath = "https://shyplitepublicassets.s3.ap-southeast-1.amazonaws.com/assets/uploadFormats/Shyplite.KYC.Authorization.docx"

			    this.download(samplePath, "Authorization Letter.docx")
			    this.popToast("requested","Download requested","Your download should start now.");
			},

			async intlGet() {
				let result = await this.axios.get('/choice/intl');
				if(result.data.intlStatus == 1) {
					this.$store.commit('setTab', 0)
				}

				if (result.data.intlKycStatus && (this.$store.getters.global.csbRedirect || this.$store.getters.global.csbRejected)) {
					this.form.customType = 'CSB V';
				} 
				else if(result.data.intlKycStatus) {
					this.$router.push('/new')
				}


				this.user = result.data

				this.form = {
					gstno: result.data.seller.gstno,
					panNo: this.user.panNo,
					iecNo: result.data.iecNo,
					customType: !(this.$store.getters.global.csbRedirect || this.$store.getters.global.csbRejected) ? result.data.customType : 'CSB V',
					adCode: result.data.adCode,
					adbankaccount : result.data.adbankaccount,
					lutExpiryDate: result.data.lutExpiryDate && result.data.lutExpiryDate != 'Invalid date' ? new Date(result.data.lutExpiryDate) : '',
					kycAgree : 'Checked',
					panImage : this.user.isIntlPanApproved == 1 || this.user.isIntlPanApproved == 2 ? new File([""],'panImage') : null,
					gstnImage: this.user.isIntlGstnApproved == 1 || this.user.isIntlGstnApproved == 2 ? new File([""],'gstnImage') : null,
					iecImage: this.user.isIecApproved == 1 || this.user.isIecApproved == 2 ? new File([""],'iecImage') : null,
					loufile : this.user.louApproved == 1 || this.user.louApproved == 2 ? new File([""],'loufile') : null,
					authfile : this.user.authLetterApproved == 1 || this.user.authLetterApproved == 2 ? new File([""],'authfile') : null,
					adFile : this.user.isAdApproved == 1 || this.user.isAdApproved == 2 ? new File([""],'authfile') : null
				}

				if (this.form.panNo && this.form.panNo.length) {
					this.freezePan = true; 
				}
				if (this.form.gstno && this.form.gstno.length) {
					this.freezeGst = true; 
				}
				this.isBusy = false

			},

			validate(file,type) {

				if (type == 'pan' || type == 'gst' || type =='iec') {

					if (file && (!(file.name.split('.').pop().toLowerCase().includes('png') || file.name.split('.').pop().toLowerCase().includes('jpeg') || file.name.split('.').pop().toLowerCase().includes('jpg') || file.name.split('.').pop().toLowerCase().includes('pdf')))) {
						this.popToast('failed','Failed','Only PDF, PNG, JPG or JPEG file format is supported')
						if (type == 'pan') {
							this.form.panImage = null
						} else if (type == 'gst') {
							this.form.gstnImage = null
						} else if (type == 'iec') {
							this.form.iecImage = null
						} else {}

						return
					}
				} else {
					if (file && (!(file.name.split('.').pop().toLowerCase().includes('doc') || file.name.split('.').pop().toLowerCase().includes('pdf') || file.name.split('.').pop().toLowerCase().includes('docx')))) {
						this.popToast('failed','Failed','Only DOC, PDF or DOCX file format is supported')
						if (type == 'auth') {
							this.form.authfile = null
						} else if (type == 'lou') {
							this.form.loufile = null
						} else if (type == 'adCode') {
							this.form.adFile = null
						} else {
						}
						return
					}
				}
					
				if(file && file.size > 5000*1024) {
					this.popToast('failed','Failed',`File size is ${Math.round((file.size/1024)/1000)}MB, It should be less than 5MB.`)
					if (type == 'pan') {
						this.form.panImage = null
					} else if (type == 'gst') {
						this.form.gstnImage = null
					} else if (type == 'iec') {
						this.form.iecImage = null
					} else if (type == 'auth') {
						this.form.authfile = null
					} else if (type == 'lou') {
						this.form.loufile = null
					} else if (type == 'adCode') {
						this.form.adFile = null
					} else {}

					return
				}

				if (this.form.authfile && this.form.loufile && this.form.authfile.name == this.form.loufile.name && type == "auth") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.authfile = null
					return false
				}
				if(this.form.authfile && this.form.loufile && this.form.loufile.name == this.form.authfile.name && type == "lou") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.loufile = null
					return false
				}

				if(this.form.adFile && this.form.loufile && this.form.adFile.name == this.form.loufile.name && type == "adCode") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.adFile = null
					return false
				}


				if (this.form.panImage && this.form.gstnImage && this.form.panImage.name == this.form.gstnImage.name && type == "pan") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.panImage = null
					return false
				}
				if(this.form.panImage && this.form.iecImage && this.form.panImage.name == this.form.iecImage.name && type == "pan") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.panImage = null
					return false
				}

				if (this.form.gstnImage && this.form.panImage && this.form.gstnImage.name == this.form.panImage.name && type == "gst") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.gstnImage = null
					return false
				}
				if(this.form.gstnImage && this.form.iecImage && this.form.gstnImage.name == this.form.iecImage.name && type == "gst") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.gstnImage = null
					return false
				}

				if (this.form.iecImage && this.form.panImage && this.form.iecImage.name == this.form.panImage.name && type == "iec") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.iecImage = null
					return false
				}
				if(this.form.iecImage && this.form.gstnImage && this.form.iecImage.name == this.form.gstnImage.name && type == "iec") {
					this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
					this.form.iecImage = null
					return false
				}

			},

			async kycSubmit() {


				if (this.user.isIntlPanApproved == 1 || this.user.isIntlPanApproved == 2) {
					delete this.form.panImage
				}

				if (this.user.isIntlGstnApproved == 1 || this.user.isIntlGstnApproved == 2) {
					delete this.form.gstnImage
				} 

				if (this.user.isIecApproved == 1 || this.user.isIecApproved == 2) {
					delete this.form.iecImage
				} 

				if(this.user.louApproved == 1 || this.user.louApproved == 2) {
					delete this.form.loufile
				}

				if(this.user.authLetterApproved == 1 || this.user.authLetterApproved == 2) {
					delete this.form.authfile
				}

				if(this.user.isAdApproved == 1 || this.user.isAdApproved == 2) {
					delete this.form.adFile
				}


				if (this.user.seller && this.user.seller.agreementData.agreementID) {
					this.form['agreementID'] = this.user.seller.agreementData.agreementID;
				} else {
					this.form['agreementID'] = 0;
				}
				
				this.form['csbRequest'] = this.$store.getters.global.csbRedirect // new Request

				this.form['csbRejected'] = this.$store.getters.global.csbRejected // Updated Request after rejection

			    let kycData = new FormData();

		    	for (const key of Object.keys(this.form)) {
		        	kycData.append(key, this.form[key]);
		    	}

				try {
					this.submitting = true
					let result = await this.axios.post('/intl/kyc', kycData);

					if (result.data.status == true) {
						this.submitting = false
	                    this.popToast("booked", "Success!", result.data.message);

	                    setTimeout(() => { 
	                      this.$router.push('/new');
	                    }, 2000)
	                    
	                }
	                if(result.data.status == false) {
						this.submitting = false
	                    this.popToast("failed", "Failed!", result.data.message);
	                }

				}
				catch(err){
					console.log(err)
				}
			},

		}
	}

</script>

<style lang="scss" scoped>
/deep/ a:hover, a:focus, a:active {
	color: #006eff !important;
}
/deep/ .xmx-input:disabled, .xmx-input.disabled {
	color: unset;
	background: #e3e7ed;
}
/deep/ .custom-control-label::before {
	left: -1.615rem!important;
}
</style>

