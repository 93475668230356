<template>
	<div class="container-fluid pt-20 pb-24">
		<div class="card calculator">
			<div class="card__content">
				<!-- <span class="fs18 lh24 mb-16 d-block fw500">
					Hyperlocal
				</span>
				<div v-if="this.windowWidth > 767">
					<b-table
					:items="hyperlocalItems"
					:fields="hyperlocalFields"
					sticky-header
					no-border-collapse
					id="hyperlocal"
					class="price-calculator-table"
					></b-table>
				</div>
				<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
					<li
					v-for="hyperlocalItem in hyperlocalItems"
					:key="hyperlocalItem.carrier"
					>
					<span class="d-block fs16 fw500 mb-8">
						{{ hyperlocalItem.carrier }}
					</span>
					<div class="card-wrap card-wrap--50">
						<div class="item">
							<span class="item-title">
								Min Distance
							</span>
							<span class="item-value">
								{{ hyperlocalItem.minDistance }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Base Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.basePrice }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Add. KM Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.addKmPrice }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Add. KG Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.addKgPrice }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Outer NCR Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.outerNcrPrice }}
							</span>
						</div>
					</div>
				</li>
			</ul> -->
			<span class="fs18 lh24 pt-12 pb-16 d-block fw500">
				Your Billing Prices
			</span>
			<div v-if="this.windowWidth > 767">
				<b-table-simple
				sticky-header
				no-border-collapse
				id="air-mode"
				class="price-calculator-table"
				>
					
				<b-thead head-variant="light" class="table-head">
			        <b-tr>
			            <b-th colspan="2">Zone/Mode</b-th>
			            <b-th >Within City</b-th>
			            <b-th >Within Zone</b-th>
			            <b-th >Between Metro</b-th>
			            <b-th >Rest Of India</b-th>
			            <b-th >Special</b-th>
			        </b-tr>
			    </b-thead>
			    <b-tbody>
			    	<b-tr class="modes">
			    		<b-td class="row-head">Air</b-td>
			    		<b-td class="sub-row-head">Every 500g</b-td>
			    		<b-td v-for="(item,index) of air[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<!-- <b-tr>
			    		<b-td class="row-head" rowspan="2">Surface-30kg</b-td>
			    		<b-td class="sub-row-head">Upto 30kg</b-td>
			    		<b-td v-for="(item,index) of ThirtyKG[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr class="modes">
			    		<b-td class="sub-row-head">Additional 1Kg</b-td>
			    		<b-td v-for="(item,index) of Thirtykgother[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr> -->
			    	<b-tr>
			    		<b-td class="row-head" rowspan="2">Surface-10kg</b-td>
			    		<b-td class="sub-row-head">Upto 10kg</b-td>
			    		<b-td v-for="(item,index) of tenKG[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr class="modes">
			    		<b-td class="sub-row-head">Additional 1Kg</b-td>
			    		<b-td v-for="(item,index) of tenKGother[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr>
			    		<b-td class="row-head" rowspan="2">Surface-5kg</b-td>
			    		<b-td class="sub-row-head">Upto 5kg</b-td>
			    		<b-td v-for="(item,index) of FiveKG[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr class="modes">
			    		<b-td class="sub-row-head">Additional 1Kg</b-td>
			    		<b-td v-for="(item,index) of FiveKGother[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr>
			    		<b-td class="row-head" rowspan="2">Lite-2kg</b-td>
			    		<b-td class="sub-row-head">Upto-2kg</b-td>
			    		<b-td v-for="(item,index) of twoKG[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr class="modes">
			    		<b-td class="sub-row-head">Additional 1Kg</b-td>
			    		<b-td v-for="(item,index) of twoKGother[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr>
			    		<b-td class="row-head" rowspan="2">Lite-1kg</b-td>
			    		<b-td class="sub-row-head">First 1kg</b-td>
			    		<b-td v-for="(item,index) of oneKG[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr class="modes">
			    		<b-td class="sub-row-head">Additional 1Kg</b-td>
			    		<b-td v-for="(item,index) of oneKGother[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr>
			    		<b-td class="row-head" rowspan="2">Lite-0.5kg</b-td>
			    		<b-td class="sub-row-head">First 500g</b-td>
			    		<b-td v-for="(item,index) of halfKG[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	<b-tr class="modes">
			    		<b-td class="sub-row-head">Additional 500g</b-td>
			    		<b-td v-for="(item,index) of halfKGother[0]" :key="index">
			    			{{item}}
			    		</b-td>
			    	</b-tr>

			    </b-tbody>
				</b-table-simple>



				<b-table-simple
				sticky-header
				no-border-collapse
				id="air-mode"
				class="price-calculator-table mt-50"
				>
					
				<b-thead head-variant="light">
			        <b-tr>
			            <b-th >Address Correction</b-th>
			            <b-th >RTO</b-th>
			            <b-th >COD %</b-th>
			            <b-th >COD Rate</b-th>
			            <b-th >Reverse</b-th>
			        </b-tr>
			    </b-thead>
			    <b-tbody>
			    	<b-tr>
			    		<b-td v-for="(item,index) of addressCorr[0]" :key="item">
			    			{{item}}
			    		</b-td>
			    	</b-tr>
			    	
			    </b-tbody>
				</b-table-simple>
			</div>


			<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
				<li v-for="airmodeItem in airmodeItems" :key="airmodeItem.carrier">
					<span class="d-block fs16 fw500 mb-8">
						{{ airmodeItem.carrier }}
					</span>
					<div class="card-wrap">
						<div class="item">
							<span class="item-title">
								Within City
							</span>
							<span class="item-value">
								{{ airmodeItem.withinCity }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Within Zone
							</span>
							<span class="item-value">
								{{ airmodeItem.withinZone }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Metro
							</span>
							<span class="item-value">
								{{ airmodeItem.metro }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Rest of India
							</span>
							<span class="item-value">
								{{ airmodeItem.restOfIndia }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Special
							</span>
							<span class="item-value">
								{{ airmodeItem.special }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Min COD
							</span>
							<span class="item-value">
								{{ airmodeItem.minCod }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								COD%
							</span>
							<span class="item-value">
								{{ airmodeItem.cod }}
							</span>
						</div>
					</div>
				</li>
			</ul>
	</div>
</div>
</div>
</template>

<script>
export default {
	name: "CalculatorTables",
	data() {
		return {
			isBusy:false,
			modes:["","Bookawheel","Delhivery","EcomExpress","Fedex","Gojavas","Xpressbees","DTDC-Dotzot","Aramex","NECC","Holisol","Bluedart","Gati","Safexpress","Nuvoex","OnlineXpress","Shadowfax","DHL","WowExpress","Ekart","Trackon","SpotOn","Professional","Shree-Maruti","V-Express","Lala","Dunzo","Wefast","Udaan","SWA","Shypmax","EcomExpress-ROS","Blowhorn","DTDC-Plus","SmartrExpress"],
			hyperlocalFields: [
			{ key: "carrier", sortable: true },
			{
				key: "minDistance",
				label: "Min Distance",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "basePrice",
				label: "Base Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "addKmPrice",
				label: "Additional-KM Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "addKgPrice",
				label: "Additional-KG Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "outerNcrPrice",
				label: "Outer-NCR Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			],
			airmodeFields: [
			{ key: "carrier",label:"Zone/Mode", sortable: true },
			{
				key: "withinCity",
				label: "Within City",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "withinZone",
				label: "Within Zone",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "metro",
				label: "Between Metro",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "restOfIndia",
				label: "Rest of India",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "special",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			}
			],
			hyperlocalItems: [
			{
				carrier: "Dunzo",
				minDistance: 3000,
				basePrice: 46,
				addKmPrice: 23,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			{
				carrier: "Lala",
				minDistance: 1000,
				basePrice: 46,
				addKmPrice: 17,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			{
				carrier: "ShadowFax",
				minDistance: 8000,
				basePrice: 104,
				addKmPrice: 23,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			{
				carrier: "WeFast",
				minDistance: 3000,
				basePrice: 46,
				addKmPrice: 15,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			],
			airmodeItems: [],
			addressCorr:[],
			air:[],
			tenKG:[],
			tenKGother:[],
			FiveKG:[],
			FiveKGother:[],
			twoKG:[],
			twoKGother:[],
			oneKG:[],
			oneKGother:[],
			halfKG:[],
			halfKGother:[],
			ThirtyKG:[],
			Thirtykgother :[]

		};
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
	},
	mounted() {
		this.tableData()
	},
	methods:{
		async tableData(){
			this.isBusy = true
			const res = await this.axios.get('/settings/pricecalculator')
			for (let item of Object.keys(res.data.billingPrices)) {
				
				this.addressCorr.push({
					add:res.data.billingPrices.addressRedirect,
					rto:"Same as Forward",
					codpct:`${res.data.billingPrices.codPcnt}%`,
					cod:res.data.billingPrices.codRate,
					rev:`${res.data.billingPrices.reverse} X Forward`
				})
				this.air.push({
					add:res.data.billingPrices.airZone1,
					rto:res.data.billingPrices.airZone2,
					codpct:res.data.billingPrices.airZone3,
					cod:res.data.billingPrices.airZone4,
					rev:res.data.billingPrices.airZone5
				})
				this.tenKGother.push({
					add:res.data.billingPrices.sfZone1,
					rto:res.data.billingPrices.sfZone2,
					codpct:res.data.billingPrices.sfZone3,
					cod:res.data.billingPrices.sfZone4,
					rev:res.data.billingPrices.sfZone5
				})
				this.tenKG.push({
					add:res.data.billingPrices.sfZone1 * 10 + res.data.billingPrices.sfWaybill,
					rto:res.data.billingPrices.sfZone2 * 10 + res.data.billingPrices.sfWaybill,
					codpct:res.data.billingPrices.sfZone3 * 10 + res.data.billingPrices.sfWaybill,
					cod:res.data.billingPrices.sfZone4 * 10 + res.data.billingPrices.sfWaybill,
					rev:res.data.billingPrices.sfZone5 * 10 + res.data.billingPrices.sfWaybill
				})
				this.FiveKG.push({
					add:res.data.billingPrices.sf5Zone1,
					rto:res.data.billingPrices.sf5Zone2,
					codpct:res.data.billingPrices.sf5Zone3,
					cod:res.data.billingPrices.sf5Zone4,
					rev:res.data.billingPrices.sf5Zone5
				})
				this.FiveKGother.push({
					add:res.data.billingPrices.sf5Zone1other,
					rto:res.data.billingPrices.sf5Zone2other,
					codpct:res.data.billingPrices.sf5Zone3other,
					cod:res.data.billingPrices.sf5Zone4other,
					rev:res.data.billingPrices.sf5Zone5other
				})
				this.ThirtyKG.push({
					add:res.data.billingPrices.sf30Zone1,
					rto:res.data.billingPrices.sf30Zone2,
					codpct:res.data.billingPrices.sf30Zone3,
					cod:res.data.billingPrices.sf30Zone4,
					rev:res.data.billingPrices.sf30Zone5
				})
				this.Thirtykgother.push({
					add:res.data.billingPrices.sf30OtherZone1,
					rto:res.data.billingPrices.sf30OtherZone2,
					codpct:res.data.billingPrices.sf30OtherZone3,
					cod:res.data.billingPrices.sf30OtherZone4,
					rev:res.data.billingPrices.sf30OtherZone5
				})
				this.twoKG.push({
					add:res.data.billingPrices.lite,
					rto:res.data.billingPrices.lite,
					codpct:res.data.billingPrices.lite,
					cod:res.data.billingPrices.lite,
					rev:res.data.billingPrices.lite
				})
				this.twoKGother.push({
					add:res.data.billingPrices.liteother,
					rto:res.data.billingPrices.liteother,
					codpct:res.data.billingPrices.liteother,
					cod:res.data.billingPrices.liteother,
					rev:res.data.billingPrices.liteother
				})
				this.oneKG.push({
					add:res.data.billingPrices.liteonekgfirst,
					rto:res.data.billingPrices.liteonekgfirst,
					codpct:res.data.billingPrices.liteonekgfirst,
					cod:res.data.billingPrices.liteonekgfirst,
					rev:res.data.billingPrices.liteonekgfirst
				})
				this.oneKGother.push({
					add:res.data.billingPrices.liteonekgother,
					rto:res.data.billingPrices.liteonekgother,
					codpct:res.data.billingPrices.liteonekgother,
					cod:res.data.billingPrices.liteonekgother,
					rev:res.data.billingPrices.liteonekgother
				})
				this.halfKG.push({
					add:res.data.billingPrices.litehalfkgfirst,
					rto:res.data.billingPrices.litehalfkgfirst,
					codpct:res.data.billingPrices.litehalfkgfirst,
					cod:res.data.billingPrices.litehalfkgfirst,
					rev:res.data.billingPrices.litehalfkgfirst
				})
				this.halfKGother.push({
					add:res.data.billingPrices.litehalfkgother,
					rto:res.data.billingPrices.litehalfkgother,
					codpct:res.data.billingPrices.litehalfkgother,
					cod:res.data.billingPrices.litehalfkgother,
					rev:res.data.billingPrices.litehalfkgother
				})
				
			}
		}
	}
};
</script>

<style scoped type="text/scss">
	.b-table-sticky-header {
		max-height: max-content !important;
		overflow-y: auto;
		min-height: unset !important;
	}

	.b-table-sticky-header > .table.b-table > thead > tr > th  {
		font-weight: bold !important;
	    background-color: #3f5579c7 !important;
	    color: #FFF !important;
	    border-bottom: 1px solid #ccc !important;
	    border-right: 1px solid #ccc !important;
	    text-align: center !important;
	}

	.b-table-sticky-header > .table.b-table > tbody > tr > .row-head {
		font-weight: bold !important;
	    background-color: #3f5579c7 !important;
	    color: #FFF !important;
	    border-left: 1px solid #ccc !important;
	    border-right: 1px solid #ccc !important;
	    text-align: center !important;
	}

	.b-table-sticky-header > .table.b-table > tbody .sub-row-head {
		    background-color: #e7f0ff !important;
		    color: #3F5579 !important;
		    font-weight: bold !important;
		    font-size: 11px !important;
		    text-align: center !important;
	}

	.b-table-sticky-header > .table.b-table > tbody {
		text-align: center !important;
	}



</style>

