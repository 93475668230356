var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid pt-20 pb-24" }, [
    _c("div", { staticClass: "card calculator" }, [
      _c("div", { staticClass: "card__content" }, [
        _c("span", { staticClass: "fs18 lh24 pt-12 pb-16 d-block fw500" }, [
          _vm._v(" Your Billing Prices ")
        ]),
        this.windowWidth > 767
          ? _c(
              "div",
              [
                _c(
                  "b-table-simple",
                  {
                    staticClass: "price-calculator-table",
                    attrs: {
                      "sticky-header": "",
                      "no-border-collapse": "",
                      id: "air-mode"
                    }
                  },
                  [
                    _c(
                      "b-thead",
                      {
                        staticClass: "table-head",
                        attrs: { "head-variant": "light" }
                      },
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-th", { attrs: { colspan: "2" } }, [
                              _vm._v("Zone/Mode")
                            ]),
                            _c("b-th", [_vm._v("Within City")]),
                            _c("b-th", [_vm._v("Within Zone")]),
                            _c("b-th", [_vm._v("Between Metro")]),
                            _c("b-th", [_vm._v("Rest Of India")]),
                            _c("b-th", [_vm._v("Special")])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      [
                        _c(
                          "b-tr",
                          { staticClass: "modes" },
                          [
                            _c("b-td", { staticClass: "row-head" }, [
                              _vm._v("Air")
                            ]),
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Every 500g")
                            ]),
                            _vm._l(_vm.air[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          [
                            _c(
                              "b-td",
                              {
                                staticClass: "row-head",
                                attrs: { rowspan: "2" }
                              },
                              [_vm._v("Surface-10kg")]
                            ),
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Upto 10kg")
                            ]),
                            _vm._l(_vm.tenKG[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          { staticClass: "modes" },
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Additional 1Kg")
                            ]),
                            _vm._l(_vm.tenKGother[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          [
                            _c(
                              "b-td",
                              {
                                staticClass: "row-head",
                                attrs: { rowspan: "2" }
                              },
                              [_vm._v("Surface-5kg")]
                            ),
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Upto 5kg")
                            ]),
                            _vm._l(_vm.FiveKG[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          { staticClass: "modes" },
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Additional 1Kg")
                            ]),
                            _vm._l(_vm.FiveKGother[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          [
                            _c(
                              "b-td",
                              {
                                staticClass: "row-head",
                                attrs: { rowspan: "2" }
                              },
                              [_vm._v("Lite-2kg")]
                            ),
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Upto-2kg")
                            ]),
                            _vm._l(_vm.twoKG[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          { staticClass: "modes" },
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Additional 1Kg")
                            ]),
                            _vm._l(_vm.twoKGother[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          [
                            _c(
                              "b-td",
                              {
                                staticClass: "row-head",
                                attrs: { rowspan: "2" }
                              },
                              [_vm._v("Lite-1kg")]
                            ),
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("First 1kg")
                            ]),
                            _vm._l(_vm.oneKG[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          { staticClass: "modes" },
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Additional 1Kg")
                            ]),
                            _vm._l(_vm.oneKGother[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          [
                            _c(
                              "b-td",
                              {
                                staticClass: "row-head",
                                attrs: { rowspan: "2" }
                              },
                              [_vm._v("Lite-0.5kg")]
                            ),
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("First 500g")
                            ]),
                            _vm._l(_vm.halfKG[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          { staticClass: "modes" },
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v("Additional 500g")
                            ]),
                            _vm._l(_vm.halfKGother[0], function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-table-simple",
                  {
                    staticClass: "price-calculator-table mt-50",
                    attrs: {
                      "sticky-header": "",
                      "no-border-collapse": "",
                      id: "air-mode"
                    }
                  },
                  [
                    _c(
                      "b-thead",
                      { attrs: { "head-variant": "light" } },
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-th", [_vm._v("Address Correction")]),
                            _c("b-th", [_vm._v("RTO")]),
                            _c("b-th", [_vm._v("COD %")]),
                            _c("b-th", [_vm._v("COD Rate")]),
                            _c("b-th", [_vm._v("Reverse")])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      [
                        _c(
                          "b-tr",
                          _vm._l(_vm.addressCorr[0], function(item, index) {
                            return _c("b-td", { key: item }, [
                              _vm._v(" " + _vm._s(item) + " ")
                            ])
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _c(
              "ul",
              {
                staticClass:
                  "table-cards table-cards--calculator d-flex d-md-none"
              },
              _vm._l(_vm.airmodeItems, function(airmodeItem) {
                return _c("li", { key: airmodeItem.carrier }, [
                  _c("span", { staticClass: "d-block fs16 fw500 mb-8" }, [
                    _vm._v(" " + _vm._s(airmodeItem.carrier) + " ")
                  ]),
                  _c("div", { staticClass: "card-wrap" }, [
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" Within City ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.withinCity) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" Within Zone ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.withinZone) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" Metro ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.metro) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" Rest of India ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.restOfIndia) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" Special ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.special) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" Min COD ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.minCod) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v(" COD% ")
                      ]),
                      _c("span", { staticClass: "item-value" }, [
                        _vm._v(" " + _vm._s(airmodeItem.cod) + " ")
                      ])
                    ])
                  ])
                ])
              }),
              0
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }