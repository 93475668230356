var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Price Calculator",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "new"
        }
      }),
      _vm.user.choice == 1
        ? _c(
            "div",
            { staticClass: "container-fluid pt-70 pt-md-98" },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.calPricing()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card__title" }, [
                      _c("span", { staticClass: "fs18 lh24 fw500" }, [
                        _vm._v(" Order Information ")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "card__content overflow-visible" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "pickup-pincode-field",
                                    label: "Pickup Pin Code",
                                    "label-for": "pickup-pin-code"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      pattern: "[0-9]+",
                                      required: "",
                                      id: "pickup-pin-code",
                                      placeholder: "Source Pin Code",
                                      formatter: _vm.maxsix,
                                      type: "number",
                                      state: _vm.pincode
                                    },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.numberValidation(
                                          $event.target.value,
                                          "pin1"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.originPin,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "originPin", $$v)
                                      },
                                      expression: "form.originPin"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "delivery-pincode-field",
                                    label: "Delivery Pin Code",
                                    "label-for": "delivery-pin-code"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      pattern: "[0-9]+",
                                      id: "delivery-pin-code",
                                      placeholder: "Destination Pin Code",
                                      formatter: _vm.maxsix,
                                      type: "number",
                                      state: _vm.pincode1
                                    },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.numberValidation(
                                          $event.target.value,
                                          "pin2"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.customerPinCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "customerPinCode",
                                          $$v
                                        )
                                      },
                                      expression: "form.customerPinCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "order-type-field",
                                    label: "Order Type",
                                    "label-for": "order-type"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      id: "order-type",
                                      options: _vm.options,
                                      searchable: false,
                                      "show-labels": false
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.selectType($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.form.orderType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "orderType", $$v)
                                      },
                                      expression: "form.orderType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "card__title" }, [
                      _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass: "fs18 lh24 fw500",
                          attrs: {
                            for: "orderId",
                            title: "Information about dimensions!"
                          }
                        },
                        [
                          _vm._v(" Package Information "),
                          _c("i", {
                            staticClass: "icon-disclaimer fs12 text-gray-400"
                          })
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "card__content" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "length-field",
                                    label: "Length (cm)",
                                    "label-for": "length"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      min: this.$store.state.minD,
                                      max: this.$store.state.maxD,
                                      required: "",
                                      id: "length",
                                      pattern: "[0-9]+",
                                      type: "number",
                                      placeholder: "0"
                                    },
                                    model: {
                                      value: _vm.form.packageLength,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "packageLength", $$v)
                                      },
                                      expression: "form.packageLength"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "width-field",
                                    label: "Width (cm)",
                                    "label-for": "width"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      id: "width",
                                      min: this.$store.state.minD,
                                      max: this.$store.state.maxD,
                                      pattern: "[0-9]+",
                                      type: "number",
                                      placeholder: "0"
                                    },
                                    model: {
                                      value: _vm.form.packageWidth,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "packageWidth", $$v)
                                      },
                                      expression: "form.packageWidth"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "height-field",
                                    label: "Height (cm)",
                                    "label-for": "height"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      id: "height",
                                      min: this.$store.state.minD,
                                      max: this.$store.state.maxD,
                                      pattern: "[0-9]+",
                                      type: "number",
                                      placeholder: "0"
                                    },
                                    model: {
                                      value: _vm.form.packageHeight,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "packageHeight", $$v)
                                      },
                                      expression: "form.packageHeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-2 col-6" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group wsnw",
                                attrs: {
                                  id: "weight-field",
                                  label: "Weight (kg)",
                                  "label-for": "weight"
                                }
                              },
                              [
                                _c("label", { attrs: { for: "weight" } }, [
                                  _vm._v("Weight (kg)")
                                ]),
                                _c("b-form-input", {
                                  attrs: {
                                    required: "",
                                    min: this.$store.state.minW,
                                    max: this.$store.state.maxW,
                                    id: "weight",
                                    step: ".01",
                                    pattern: "[0-9]+",
                                    type: "number",
                                    placeholder: "0.00"
                                  },
                                  model: {
                                    value: _vm.form.packageWeight,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "packageWeight", $$v)
                                    },
                                    expression: "form.packageWeight"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm.totalValue
                            ? _c(
                                "div",
                                { staticClass: "col-md-2 col-6" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "height-field",
                                        label: "Invoice Value",
                                        "label-for": "height"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          required: "",
                                          min: this.$store.state.minD,
                                          max: this.$store.state.maxI,
                                          id: "height",
                                          step: ".01",
                                          pattern: "[0-9]+",
                                          type: "number",
                                          placeholder: "0.00"
                                        },
                                        model: {
                                          value: _vm.form.totalValue,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "totalValue",
                                              $$v
                                            )
                                          },
                                          expression: "form.totalValue"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("ul", { staticClass: "info-card sm" }, [
                          _c("li", [
                            _c("span", [
                              _vm._v(
                                " Quoted charges are all inclusive of 18% GST. "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("span", [
                              _vm._v(
                                " This is courtesy rate, final bill may differ. "
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                !(
                                  _vm.isComplete &&
                                  (_vm.pincode || _vm.pincode == "null") &&
                                  (_vm.pincode1 || _vm.pincode1 == "null")
                                ) || _vm.submitting,
                              type: "submit",
                              variant: "primary mt-20"
                            }
                          },
                          [
                            _vm.submitting ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Get Pricing ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "container-fluid pt-70 pt-md-98" },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.calTable()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card__title" }, [
                      _c("span", { staticClass: "fs18 lh24 fw500" }, [
                        _vm._v(" Order Information ")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "card__content overflow-visible" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "pickup-pincode-field",
                                    label: "Pickup Pin Code",
                                    "label-for": "pickup-pin-code"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      pattern: "[0-9]+",
                                      id: "pickup-pin-code",
                                      placeholder: "Source Pin Code",
                                      formatter: _vm.maxsix,
                                      type: "number",
                                      state: _vm.pincode
                                    },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.numberValidation(
                                          $event.target.value,
                                          "pin1"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.sourcePin,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "sourcePin", $$v)
                                      },
                                      expression: "form.sourcePin"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "delivery-pincode-field",
                                    label: "Delivery Pin Code",
                                    "label-for": "delivery-pin-code"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      pattern: "[0-9]+",
                                      id: "delivery-pin-code",
                                      placeholder: "Destination Pin Code",
                                      formatter: _vm.maxsix,
                                      type: "number",
                                      state: _vm.pincode1
                                    },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.numberValidation(
                                          $event.target.value,
                                          "pin2"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.destinationPin,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "destinationPin",
                                          $$v
                                        )
                                      },
                                      expression: "form.destinationPin"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "order-type-field",
                                    label: "Order Type",
                                    "label-for": "order-type"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      id: "order-type",
                                      options: _vm.options,
                                      searchable: false,
                                      "show-labels": false
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.selectType($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.form.orderType2,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "orderType2", $$v)
                                      },
                                      expression: "form.orderType2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "card__title" }, [
                      _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass: "fs18 lh24 fw500",
                          attrs: {
                            for: "orderId",
                            title: "Information about dimensions!"
                          }
                        },
                        [
                          _vm._v(" Package Information "),
                          _c("i", {
                            staticClass: "icon-disclaimer fs12 text-gray-400"
                          })
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "card__content" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "length-field",
                                    label: "Length (cm)",
                                    "label-for": "length"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      min: this.$store.state.minD,
                                      max: this.$store.state.maxD,
                                      required: "",
                                      id: "length",
                                      pattern: "[0-9]+",
                                      type: "number",
                                      placeholder: "0"
                                    },
                                    model: {
                                      value: _vm.form.length,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "length", $$v)
                                      },
                                      expression: "form.length"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "width-field",
                                    label: "Width (cm)",
                                    "label-for": "width"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      id: "width",
                                      min: this.$store.state.minD,
                                      max: this.$store.state.maxD,
                                      pattern: "[0-9]+",
                                      type: "number",
                                      placeholder: "0"
                                    },
                                    model: {
                                      value: _vm.form.width,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "width", $$v)
                                      },
                                      expression: "form.width"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "height-field",
                                    label: "Height (cm)",
                                    "label-for": "height"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      id: "height",
                                      min: this.$store.state.minD,
                                      max: this.$store.state.maxD,
                                      pattern: "[0-9]+",
                                      type: "number",
                                      placeholder: "0"
                                    },
                                    model: {
                                      value: _vm.form.height,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "height", $$v)
                                      },
                                      expression: "form.height"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-2 col-6" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group wsnw",
                                attrs: {
                                  id: "weight-field",
                                  label: "Weight (kg)",
                                  "label-for": "weight"
                                }
                              },
                              [
                                _c("label", { attrs: { for: "weight" } }, [
                                  _vm._v("Weight (kg)")
                                ]),
                                _c("b-form-input", {
                                  attrs: {
                                    required: "",
                                    min: this.$store.state.minW,
                                    max: this.$store.state.maxW,
                                    id: "weight",
                                    step: ".01",
                                    pattern: "[0-9]+",
                                    type: "number",
                                    placeholder: "0.00"
                                  },
                                  model: {
                                    value: _vm.form.weight,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "weight", $$v)
                                    },
                                    expression: "form.weight"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm.totalValue
                            ? _c(
                                "div",
                                { staticClass: "col-md-2 col-6" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "height-field",
                                        label: "Invoice Value",
                                        "label-for": "height"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          required: "",
                                          min: this.$store.state.minD,
                                          step: ".01",
                                          max: this.$store.state.maxI,
                                          id: "height",
                                          pattern: "[0-9]+",
                                          type: "number",
                                          placeholder: "0.00"
                                        },
                                        model: {
                                          value: _vm.form.invoice,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "invoice", $$v)
                                          },
                                          expression: "form.invoice"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("ul", { staticClass: "info-card sm" }, [
                          _c("li", [
                            _c("span", [
                              _vm._v(
                                " Quoted charges are all inclusive of 18% GST. "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("span", [
                              _vm._v(
                                " This is courtesy rate, final bill may differ. "
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                !(
                                  _vm.isComplete &&
                                  (_vm.pincode || _vm.pincode == "null") &&
                                  (_vm.pincode1 || _vm.pincode1 == "null")
                                ) || _vm.autosubmitting,
                              type: "submit",
                              variant: "primary mt-20"
                            }
                          },
                          [
                            _vm.autosubmitting ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Get Pricing ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
      _vm.user.choice == 1 ? _c("CalculatorTables") : _c("CalculatorTablesNC"),
      _c("CalcPricing", {
        ref: "calcpricing",
        attrs: { formInfo: _vm.calcForm, choiceData: _vm.calcData },
        on: {
          openModal: _vm.openPricingModal,
          "update:formInfo": function($event) {
            _vm.calcForm = $event
          },
          "update:form-info": function($event) {
            _vm.calcForm = $event
          },
          "update:choiceData": function($event) {
            _vm.calcData = $event
          },
          "update:choice-data": function($event) {
            _vm.calcData = $event
          }
        }
      }),
      _c("AutoCalcPricing", {
        ref: "autocalcpricing",
        attrs: {
          formInfo: _vm.autocalcForm,
          serviceTab: _vm.serviceTab,
          pricingTab: _vm.pricingTab
        },
        on: {
          openModal: _vm.openAutoPricingModal,
          "update:formInfo": function($event) {
            _vm.autocalcForm = $event
          },
          "update:form-info": function($event) {
            _vm.autocalcForm = $event
          },
          "update:serviceTab": function($event) {
            _vm.serviceTab = $event
          },
          "update:service-tab": function($event) {
            _vm.serviceTab = $event
          },
          "update:pricingTab": function($event) {
            _vm.pricingTab = $event
          },
          "update:pricing-tab": function($event) {
            _vm.pricingTab = $event
          }
        }
      }),
      _c("div", { staticClass: "container-fluid pb-80 pb-md-0" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card__content" }, [
            _c("span", { staticClass: "fs18 lh24 fw500" }, [
              _vm._v(" Terms and Conditions ")
            ]),
            _c("ul", { staticClass: "info-card mt-20" }, [
              _vm._m(0),
              _vm.$store.getters.user.choice == 1
                ? _c("li", [
                    _c("span", [
                      _vm._v(
                        " For reverse order booking, the charges are equal to 2 times forward charges ( Reverse = 2 X Froward ) "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6)
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [_vm._v(" Quoted charges are all inclusive of 18% GST. ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          " Chargeable weight would be volumetric or actual weight, whichever is higher (Air/ Rush mode: LxBxH/ 5000 | LITE/ Surface mode: LxBxH/ 4000). "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          " Maximum liability for Forward shipping is Rs 5000 or Invoice value whichever is lower. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          " Maximum liability for Reverse shipping is Rs 2000 or 50% of Invoice value whichever is lower. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          " No Claim would be entertained for Glassware, Fragile products, Concealed damages and improper packaging. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [_vm._v(" Manual label penalty is Rs 500 per shipment. ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          " Any weight dispute due to incorrect weight declaration cannot be claimed. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }