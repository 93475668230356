<template>
  <b-modal
    ref="autocalcpricing"
    id="modal-tall"
    size="lg"
    title="International Shipment"
    hide-footer
    okOnly
    v-model="isOpen"
    centered
    modal-class="pricing-modal"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block fs18 lh28 fw500 mt-8">
        Price Calculator Chart
      </span>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>
    <!-- {{pricingTab[0]}} -->
    <template v-slot:default>
    <div class="row justify-content-between pt-8 pt-md-26 pb-22">
      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Pickup
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.originPin}}
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          {{ dialogHeader.orderType }}
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          <img src="@/assets/svg/arrow-right.svg" alt="" />
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Delivery
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.customerPinCode }}
        </span>
      </div>

      <div class="col-md-auto col-4 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet Weight
        </span>
        <span class="fs14 lh20 text-center text-gray-900 mt-4 d-block">
          {{ dialogHeader.packageWeight }} Kg
        </span>
      </div>

      <div class="col-md-auto col-12 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet dimensions
          <i
            class="icon-disclaimer fs12 text-gray-400"
            v-b-tooltip.hover.right
            title="Information about dimensions!"
          ></i>
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.packageLength }}cm x {{  dialogHeader.packageWidth }}cm x {{ dialogHeader.packageHeight }}cm
        </span>
      </div>
    </div>

    <div>
        <b-table-simple
        sticky-header
        no-border-collapse
        id="air-mode"
        class="mt-50 price-calculator-table"
        >
          
        <b-thead head-variant="light">
              <b-tr>
                  <b-th class="prepaid">PREPAID</b-th>
                  <b-th >Air</b-th>
                  <b-th >Surface-10kg</b-th>
                  <b-th >Surface-5kg</b-th>
                  <b-th >Lite-2kg</b-th>
                  <b-th >Lite-1kg</b-th>
                  <b-th >Lite-0.5kg</b-th>
              </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class="sub-row-head">
                Service
              </b-td>
              <b-td v-for="(item,index) of service" :key="index">
                <img class="tick" src="@/assets/images/yes.png" v-if="item" />
                <img class="tick" src="@/assets/images/no.png" v-if="!item" />
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="sub-row-head">
                Pricing
              </b-td>
              <b-td v-for="(item,index) of pricing" :key="index">
                {{item}}
              </b-td>
            </b-tr>
            
          </b-tbody>
        </b-table-simple>
      </div>

    
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AutoCalcPricing",
  props: ['modalOpen', 'formInfo', 'serviceTab', 'pricingTab'],
  data() {
    return {
      selectedRow: null,
      isOpen: this.modalOpen,
      fields: [
        {
          key: "carrier",
          label: "Carrier",
          sortable: true,
          thClass: "pl-4 text-center",
          tdClass: "pl-4 text-center",
        },
        {
          key: "min_billing_weight",
          label: "Min. Billing Weight",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "shipping_mode",
          label: "Shipping Mode",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "estimated_delivery",
          label: "Est. Delivery",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "estimated_price",
          label: "Est. Price",
          thClass: "text-center",
          tdClass: "text-center",
        }
      ],

      // items: [
      //   {
      //     isActive: true,
      //     min_billing_weight: minweight,
      //     carrier: {
      //       name: "ups",
      //       logo_url: require("../assets/svg/ups_logo.svg"),
      //     },
      //     shipping_mode: "Surface 10kg",
      //     estimated_delivery: "1-2 days",
      //     estimated_price: 182.9,
      //     details: {
      //       transit_time: "5-10 days",
      //       pod: "On request, POD will be provided",
      //       max_liability: "USD 100",
      //       first_500: 1380,
      //       add_500: 400,
      //     },
      //   },
      // ],
      dialogHeader: {},
      rowData: [],
      form: {},
      pricing:[],
      service:[]
    };
  },

  watch: {

        formInfo: function(newValue) {
            if(newValue.sourcePin) {
                this.dialogData(newValue)
            }
        },

        pricingTab: function(value) {
          if(value.length > 0) {
            this.pricing = value
          }
        },

        serviceTab: function(value) {
          if(value.length > 0) {
            this.service = value
          }
        },

        isOpen(newValue) {
          if(!newValue) {
            this.$emit('update:formInfo', null)
            this.$emit('update:pricingTab', null)
            this.$emit('update:serviceTab', null)
          }
        }


  },
  methods: {

    dialogData(form) {



        this.dialogHeader.originPin = form.sourcePin;
        // this.dialogHeader.originCity = form.originCity;
        this.dialogHeader.orderType = form.orderType2;

        this.dialogHeader.customerPinCode = form.destinationPin;
        // this.dialogHeader.customerCity = form.customerCity;

        // this.dialogHeader.totalValue = form.totalValue;
        this.dialogHeader.packageWeight = form.weight;

        this.dialogHeader.packageLength = form.length;
        this.dialogHeader.packageWidth = form.width;

        this.dialogHeader.packageHeight = form.height;

        this.form = form;


    },
    choiceDialogData(value) {

        let disableServiceArray = new Array();
        let enabledServiceArray = new Array();

        value.map(value => {
            if(value.length){
                value.map(val => {
                    if(val.disabled || val.price == null)
                        disableServiceArray.push(val);
                    else
                        enabledServiceArray.push(val);
                })
            }
        })


        this.rowData = enabledServiceArray.map((i) => {

            return {

                carrier: i.carrier,
                minweight: i.minweight,
                mode: this.staticName(this.$store.getters.modes, i.mode),
                price: i.price,
                service: i.service,
                serviceCode: i.serviceCode,
                tat: i.tat,
            }
        })

    },
    staticName(statics, index) {
      return statics.filter(i => i.value == index)[0].name
    },

    tbodyRowClass(item) {
        if (item.selected) {
            return ["pricing-row-selected"];
        }
    },
    getImgUrl(carrier) {
        var images = require(`../assets/images/${carrier}.png`);
        return images;
     
    },
    setValue: function(value) {
      console.table(value);
    },
  },
  created: function() {
    this.$parent.$on("openModal", this.setValue);
  },
};
</script>

<style type="text/css" scoped>
  .tick {
        height: 1.5rem;
        width: 1.8rem;
  }
  .b-table-sticky-header > .table.b-table > tbody {
      text-align: center !important;
    }


  .b-table-sticky-header > .table.b-table > thead > tr > th:first-child {
        text-align: center !important;
      }

  .b-table-sticky-header {
      max-height: 1000px !important;
      overflow-y: auto;
    }

    .b-table-sticky-header > .table.b-table > thead > tr > th  {
        text-align: center !important;
    }

    .b-table-sticky-header > .table.b-table > tbody > tr > .row-head {
        text-align: center !important;
    }

    .b-table-sticky-header > .table.b-table > tbody .sub-row-head {
          text-align: center !important;
    }
</style>
