<template>
	<div class="container-fluid pt-20 pb-24">

		<div class="col p-0 mt-10" v-if="planActivate">
	      <b-form-group class="text-gray-800 fs18">
          	<span class="fs22 text-gray-800 font-weight-bold">Pricing Plans</span>
	          <div class="row pt-15">
	            <div v-for="(item,index) in rates" :key="index" class="col">
	              <div class="inputGroup" :class="{'hello':updateId}">
	                <input type="radio" @input="getRate(item)" v-model="rate" :id="item.name" :value="item.id">
  		            	<span class="check fs14" v-if="item.eligible">Eligible</span>
	                <label :for="item.name">
	                	<p>{{item.name}}</p>
	                	<p class="fs24 mt-10">₹{{item.minAmount}}</p>
	                	<p class="fs12 monthly">Minimum Monthly Recharge</p>
	                	<!-- {{item}} -->
	                	<b-button :disabled="activate[index]"class="mt-30" @click="upgradePlan(item,index)" v-if="item.eligible" variant="primary">
	                		<b-spinner v-if="activate[index]"></b-spinner>
	                		<span v-else>Activate</span>
	                	</b-button>
                		<p class="mt-32 mx-auto current" v-if="item.active">Current Plan</p>
	                	<b-button class="mt-30" @click="openaddfundsModal(item.amountToRecharge,item.id)" v-if="item.amountToRecharge > 0" variant="primary">
	                		Recharge &#8377{{item.amountToRecharge > 0 ? Math.ceil(item.amountToRecharge)  : ''}} to Activate </b-button>
	                </label>
	              </div>
	            </div>
	          </div>
	        </b-form-group>
	    </div>

	    <div v-if="(updateId && updateId.slabed == 0) && (airmodeItems.length)" class="col-md-2 mt-30 fs24 ml-5 plan_name">{{updateId.name}} Plan</div>
		<div class="card calculator">

			<div class="card__content" v-if="(airmodeItems.length || surfacehalfItems.length || liteoneKg.length || litetwoKg.length || litefiveKg.length || litetenKg.length || surfacethirtykg.length)">
				<!-- <span class="fs18 lh24 mb-16 d-block fw500">
					Hyperlocal
				</span>
				<div v-if="this.windowWidth > 767">
					<b-table
					:items="hyperlocalItems"
					:fields="hyperlocalFields"
					sticky-header
					no-border-collapse
					id="hyperlocal"
					class="price-calculator-table"
					></b-table>
				</div>
				<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
					<li
					v-for="hyperlocalItem in hyperlocalItems"
					:key="hyperlocalItem.carrier"
					>
					<span class="d-block fs16 fw500 mb-8">
						{{ hyperlocalItem.carrier }}
					</span>
					<div class="card-wrap card-wrap--50">
						<div class="item">
							<span class="item-title">
								Min Distance
							</span>
							<span class="item-value">
								{{ hyperlocalItem.minDistance }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Base Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.basePrice }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Add. KM Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.addKmPrice }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Add. KG Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.addKgPrice }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Outer NCR Price
							</span>
							<span class="item-value">
								{{ hyperlocalItem.outerNcrPrice }}
							</span>
						</div>
					</div>
				</li>
			</ul> -->

			<span v-if="airmodeItems.length" class="fs18 lh24 pt-12 pb-16 d-block fw500">
				Air Mode (every 0.5kg)
			</span>
			<div v-if="this.windowWidth > 767 && airmodeItems.length">
				<b-table
				:items="airmodeItems"
				:fields="airmodeFields"
				sticky-header
				no-border-collapse
				id="air-mode"
				class="price-calculator-table"
				></b-table>
			</div>
			<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
				<li v-for="airmodeItem in airmodeItems" :key="airmodeItem.carrier">
					<span class="d-block fs16 fw500 mb-8">
						{{ airmodeItem.carrier }}
					</span>
					<div class="card-wrap">
						<div class="item">
							<span class="item-title">
								Within City
							</span>
							<span class="item-value">
								{{ airmodeItem.withinCity }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Within Zone
							</span>
							<span class="item-value">
								{{ airmodeItem.withinZone }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Metro
							</span>
							<span class="item-value">
								{{ airmodeItem.metro }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Rest of India
							</span>
							<span class="item-value">
								{{ airmodeItem.restOfIndia }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Special
							</span>
							<span class="item-value">
								{{ airmodeItem.special }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								Min COD
							</span>
							<span class="item-value">
								{{ airmodeItem.minCod }}
							</span>
						</div>
						<div class="item">
							<span class="item-title">
								COD%
							</span>
							<span class="item-value">
								{{ airmodeItem.cod }}
							</span>
						</div>
					</div>
				</li>
			</ul>

			<span class="fs22 lh24 pt-15 pb-16 d-block fw500">Surface Modes</span>

			<span v-if="surfacehalfItems.length" class="fs18 lh24 pt-8 pb-16 d-block fw500">
				 Lite 0.5kg (every 0.5kg)
			</span>
			<div v-if="this.windowWidth > 767 && surfacehalfItems.length">
				<b-table
				:items="surfacehalfItems"
				:fields="airmodeFields"
				sticky-header
				no-border-collapse
				id="surface-lite-mode"
				class="price-calculator-table"
				>
			</b-table>
		</div>
		<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
			<li v-for="airmodeItem in surfacehalfItems" :key="airmodeItem.carrier">
				<span class="d-block fs16 fw500 mb-8">
					{{ airmodeItem.carrier }}
				</span>
				<div class="card-wrap">
					<div class="item">
						<span class="item-title">
							Within City
						</span>
						<span class="item-value">
							{{ airmodeItem.withinCity }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Within Zone
						</span>
						<span class="item-value">
							{{ airmodeItem.withinZone }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Metro
						</span>
						<span class="item-value">
							{{ airmodeItem.metro }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Rest of India
						</span>
						<span class="item-value">
							{{ airmodeItem.restOfIndia }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Special
						</span>
						<span class="item-value">
							{{ airmodeItem.special }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Min COD
						</span>
						<span class="item-value">
							{{ airmodeItem.minCod }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							COD%
						</span>
						<span class="item-value">
							{{ airmodeItem.cod }}
						</span>
					</div>
				</div>
			</li>
		</ul>

		<span v-if="liteoneKg.length" class="fs18 lh24 pt-12 pb-16 d-block fw500">
			Lite 1kg (every 1kg)
		</span>
		<div v-if="this.windowWidth > 767 && liteoneKg.length">
			<b-table
			:items="liteoneKg"
			:fields="airmodeFields"
			sticky-header
			no-border-collapse
			id="lite-one-mode"
			class="price-calculator-table"
			></b-table>
		</div>
		<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
			<li v-for="airmodeItem in liteoneKg" :key="airmodeItem.carrier">
				<span class="d-block fs16 fw500 mb-8">
					{{ airmodeItem.carrier }}
				</span>
				<div class="card-wrap">
					<div class="item">
						<span class="item-title">
							Within City
						</span>
						<span class="item-value">
							{{ airmodeItem.withinCity }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Within Zone
						</span>
						<span class="item-value">
							{{ airmodeItem.withinZone }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Metro
						</span>
						<span class="item-value">
							{{ airmodeItem.metro }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Rest of India
						</span>
						<span class="item-value">
							{{ airmodeItem.restOfIndia }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Special
						</span>
						<span class="item-value">
							{{ airmodeItem.special }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Min COD
						</span>
						<span class="item-value">
							{{ airmodeItem.minCod }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							COD%
						</span>
						<span class="item-value">
							{{ airmodeItem.cod }}
						</span>
					</div>
				</div>
			</li>
		</ul>

		<span v-if="litetwoKg.length" class="fs18 lh24 pt-12 pb-16 d-block fw500">
			Lite 2kg (every 2kg)
		</span>
		<div v-if="this.windowWidth > 767 && litetwoKg.length">
			<b-table
			:items="litetwoKg"
			:fields="airmodeFields"
			sticky-header
			no-border-collapse
			id="lite-two-mode"
			class="price-calculator-table"
			></b-table>
		</div>
		<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
			<li v-for="airmodeItem in litetwoKg" :key="airmodeItem.carrier">
				<span class="d-block fs16 fw500 mb-8">
					{{ airmodeItem.carrier }}
				</span>
				<div class="card-wrap">
					<div class="item">
						<span class="item-title">
							Within City
						</span>
						<span class="item-value">
							{{ airmodeItem.withinCity }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Within Zone
						</span>
						<span class="item-value">
							{{ airmodeItem.withinZone }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Metro
						</span>
						<span class="item-value">
							{{ airmodeItem.metro }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Rest of India
						</span>
						<span class="item-value">
							{{ airmodeItem.restOfIndia }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Special
						</span>
						<span class="item-value">
							{{ airmodeItem.special }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Min COD
						</span>
						<span class="item-value">
							{{ airmodeItem.minCod }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							COD%
						</span>
						<span class="item-value">
							{{ airmodeItem.cod }}
						</span>
					</div>
				</div>
			</li>
		</ul>

		<span v-if="litefiveKg.length" class="fs18 lh24 pt-12 pb-16 d-block fw500">
			Surface 5kg
		</span>
		<div v-if="this.windowWidth > 767 && litefiveKg.length">
			<b-table
			:items="litefiveKg"
			:fields="airmodeFields"
			sticky-header
			no-border-collapse
			id="lite-five-mode"
			class="price-calculator-table"
			></b-table>
		</div>
		<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
			<li v-for="liteItem in litefiveKg" :key="liteItem.carrier">
				<span class="d-block fs16 fw500 mb-8">
					{{ liteItem.carrier }}
				</span>
				<div class="card-wrap">
					<div class="item">
						<span class="item-title">
							Within City
						</span>
						<span class="item-value">
							{{ liteItem.withinCity }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Within Zone
						</span>
						<span class="item-value">
							{{ liteItem.withinZone }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Metro
						</span>
						<span class="item-value">
							{{ liteItem.metro }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Rest of India
						</span>
						<span class="item-value">
							{{ liteItem.restOfIndia }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Special
						</span>
						<span class="item-value">
							{{ liteItem.special }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Min COD
						</span>
						<span class="item-value">
							{{ liteItem.minCod }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							COD%
						</span>
						<span class="item-value">
							{{ liteItem.cod }}
						</span>
					</div>
				</div>
			</li>
		</ul>

		<span v-if="litetenKg.length" class="fs18 lh24 pt-12 pb-16 d-block fw500">
			Surface 10kg
		</span>
		<div v-if="this.windowWidth > 767 && litetenKg.length">
			<b-table
			:items="litetenKg"
			:fields="airmodeFields"
			sticky-header
			no-border-collapse
			id="lite-ten-mode"
			class="price-calculator-table"
			></b-table>
		</div>
		<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
			<li v-for="liteItem in litetenKg" :key="liteItem.carrier">
				<span class="d-block fs16 fw500 mb-8">
					{{ liteItem.carrier }}
				</span>
				<div class="card-wrap">
					<div class="item">
						<span class="item-title">
							Within City
						</span>
						<span class="item-value">
							{{ liteItem.withinCity }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Within Zone
						</span>
						<span class="item-value">
							{{ liteItem.withinZone }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Metro
						</span>
						<span class="item-value">
							{{ liteItem.metro }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Rest of India
						</span>
						<span class="item-value">
							{{ liteItem.restOfIndia }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Special
						</span>
						<span class="item-value">
							{{ liteItem.special }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Min COD
						</span>
						<span class="item-value">
							{{ liteItem.minCod }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							COD%
						</span>
						<span class="item-value">
							{{ liteItem.cod }}
						</span>
					</div>
				</div>
			</li>
		</ul>

		<span v-if="surfacethirtykg.length" class="fs18 lh24 pt-12 pb-16 d-block fw500">
			Surface 30kg
		</span>
		<div v-if="this.windowWidth > 767 && surfacethirtykg.length">
			<b-table
			:items="surfacethirtykg"
			:fields="airmodeFields"
			sticky-header
			no-border-collapse
			id="lite-ten-mode"
			class="price-calculator-table"
			></b-table>
		</div>
		<ul class="table-cards table-cards--calculator d-flex d-md-none" v-else>
			<li v-for="liteItem in surfacethirtykg" :key="liteItem.carrier">
				<span class="d-block fs16 fw500 mb-8">
					{{ liteItem.carrier }}
				</span>
				<div class="card-wrap">
					<div class="item">
						<span class="item-title">
							Within City
						</span>
						<span class="item-value">
							{{ liteItem.withinCity }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Within Zone
						</span>
						<span class="item-value">
							{{ liteItem.withinZone }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Metro
						</span>
						<span class="item-value">
							{{ liteItem.metro }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Rest of India
						</span>
						<span class="item-value">
							{{ liteItem.restOfIndia }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Special
						</span>
						<span class="item-value">
							{{ liteItem.special }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							Min COD
						</span>
						<span class="item-value">
							{{ liteItem.minCod }}
						</span>
					</div>
					<div class="item">
						<span class="item-title">
							COD%
						</span>
						<span class="item-value">
							{{ liteItem.cod }}
						</span>
					</div>
				</div>
			</li>
		</ul>

	</div>
	<div v-if="!(airmodeItems.length || surfacehalfItems.length || liteoneKg.length || litetwoKg.length || litefiveKg.length || litetenKg.length || surfacethirtykg.length) && submitting">
		<b-spinner></b-spinner>
	</div>
	<div class="card__content" v-if="!(airmodeItems.length || surfacehalfItems.length || liteoneKg.length || litetwoKg.length || litefiveKg.length || litetenKg.length || surfacethirtykg.length)">
		<div class="d-flex flex-column align-items-center">
          <i class="icon-empty-state text-blue pb-24 fs30"></i>
          <span class="fs14 fw500 pb-8 text-blue">
            Couldn't find any Prices for Selected Plan
          </span>
          <span class="fs14 fw500 pb-8 text-gray-600">
            Please Select Other Plan
          </span>
        </div>
	</div>
      <BillingAddFunds ref="BillingAddFunds" />
</div>
</div>

</template>

<script>
import BillingAddFunds from "@/components/BillingAddFunds.vue";

export default {
	name: "CalculatorTables",
	data() {
		return {
			updateId:null,
			activating:false,
			isBusy:false,
			modes:["","Bookawheel","Delhivery","EcomExpress","Fedex","Gojavas","Xpressbees","DTDC-Dotzot","Aramex","NECC","Holisol","Bluedart","Gati","Safexpress","Nuvoex","OnlineXpress","Shadowfax","DHL","WowExpress","Ekart","Trackon","SpotOn","Professional","Shree-Maruti","V-Express","Lala","Dunzo","Wefast","Udaan","SWA","Shypmax","EcomExpress-ROS","Blowhorn","DTDC-Plus","SmartrExpress"],
			hyperlocalFields: [
			{ key: "carrier", sortable: true },
			{
				key: "minDistance",
				label: "Min Distance",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "basePrice",
				label: "Base Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "addKmPrice",
				label: "Additional-KM Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "addKgPrice",
				label: "Additional-KG Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "outerNcrPrice",
				label: "Outer-NCR Price",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			],
			airmodeFields: [
			{ key: "carrier", sortable: true },
			{
				key: "withinCity",
				label: "Within City",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "withinZone",
				label: "Within Zone",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "metro",
				label: "Metro",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "restOfIndia",
				label: "Rest of India",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "special",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "minCod",
				label: "Min COD",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "cod",
				label: "COD %",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			],
			hyperlocalItems: [
			{
				carrier: "Dunzo",
				minDistance: 3000,
				basePrice: 46,
				addKmPrice: 23,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			{
				carrier: "Lala",
				minDistance: 1000,
				basePrice: 46,
				addKmPrice: 17,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			{
				carrier: "ShadowFax",
				minDistance: 8000,
				basePrice: 104,
				addKmPrice: 23,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			{
				carrier: "WeFast",
				minDistance: 3000,
				basePrice: 46,
				addKmPrice: 15,
				addKgPrice: 0,
				outerNcrPrice: 0,
			},
			],
			airmodeItems: [],
			surfacehalfItems:[],
			liteoneKg:[],
			litetwoKg:[],
			litefiveKg:[],
			litetenKg:[],
			surfacethirtykg:[],
			rates: [],
			rate:null,
			activate:{
				0:false,
				1:false,
				2:false,
				3:false
			},
			submitting:false,
			ratemodalData:{},
			planActivate:false
		};
	},
	components: {
		BillingAddFunds
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
	},
	mounted() {
		this.tableData()
	},
	methods:{
		openaddfundsModal(amount,id) {
	      this.$store.commit('setRateAmount', amount.toFixed(2));
	      this.$store.commit('setGlobal', {planId:id});
	      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
	    },
		async upgradePlan(obj,id) {
			console.log(id)
				try {
					this.activate[id] = true
					const res = await this.axios.post('/choice/updateTemplate',{templateID:obj.id})
					if (res.data.success) {
						this.airmodeItems = []
						this.surfacehalfItems = []
						this.liteoneKg = []
						this.litetwoKg = []
						this.litefiveKg = []
						this.litetenKg = []
						this.surfacethirtykg = []
						this.popToast('booked','Success','Plan Activated Successfully')
						this.getRate(obj);
						this.tableData();
					} else {
						this.updateId = {}
						this.popToast('failed','Failed',res.data.message)
					}
				} catch(e) {
					this.updateId = {}
					this.popToast('failed','Failed','Plan Activation Failed!! Please Try Again')
					console.log(e);
				}
			this.activate[id] = false
		},
		async getRate(obj) {
			this.selectPlan = true
			try {
				const res  = await this.axios.get(`/choice/templatePrice/${obj.id}`)
				if (res.data.success) {
					this.selectPlan = false
					this.updateId = obj
					this.airmodeItems = []
					this.surfacehalfItems = []
					this.liteoneKg = []
					this.litetwoKg = []
					this.litefiveKg = []
					this.litetenKg = []
					this.surfacethirtykg = []
					this.ratemodalData = Object.assign({},this.updateId)
					// airmode table
					this.airmodeItems = res.data.billingPrices.filter(item => item.airZone1 > 0).map((item) => {
						return {
							carrier:this.modes[item.id],
							withinCity:item.airZone1,
							withinZone:item.airZone2,
							metro:item.airZone3,
							restOfIndia:item.airZone4,
							special:item.airZone5,
							minCod:item.codRate,
							cod:item.codPcnt
						}
					});

					// lite 0.5Kg Table
				 	res.data.billingPrices.filter(item => item.liteHZone1 > 0).forEach((item, index) => {
						this.surfacehalfItems.push({
								carrier:`${this.modes[item.id]} 0.5Kg`,
								withinCity:item.liteHZone1,
								withinZone:item.liteHZone2,
								metro:item.liteHZone3,
								restOfIndia:item.liteHZone4,
								special:item.liteHZone5,
								minCod:item.codRate,
								cod:item.codPcnt,
						})
						// this.surfacehalfItems.push({
						// 		carrier:`${this.modes[item.id]} 0.5kg`,
						// 		withinCity:item.liteHOtherZone1,
						// 		withinZone:item.liteHOtherZone2,
						// 		metro:item.liteHOtherZone3,
						// 		restOfIndia:item.liteHOtherZone4,
						// 		special:item.liteHOtherZone5,
						// 		minCod:item.codRate,
						// 		cod:item.codPcnt
						// })
					});

					// lite 1Kg Table
					res.data.billingPrices.filter(item => item.lite1Zone1 > 0).forEach((item, index) => {

						this.liteoneKg.push({
								carrier:`${this.modes[item.id]} 1Kg`,
								withinCity:item.lite1Zone1,
								withinZone:item.lite1Zone2,
								metro:item.lite1Zone3,
								restOfIndia:item.lite1Zone4,
								special:item.lite1Zone5,
								minCod:item.codRate,
								cod:item.codPcnt,
						})
						this.liteoneKg.push({
								carrier:`${this.modes[item.id]} add. 1kg`,
								withinCity:item.lite1OtherZone1,
								withinZone:item.lite1OtherZone2,
								metro:item.lite1OtherZone3,
								restOfIndia:item.lite1OtherZone4,
								special:item.lite1OtherZone5,
								minCod:item.codRate,
								cod:item.codPcnt
						})
					});

					//lite 2Kg Table
					res.data.billingPrices.filter(item => item.lite2Zone1 > 0).forEach((item, index) => {
						this.litetwoKg.push({
								carrier:`${this.modes[item.id]} 2Kg`,
								withinCity:item.lite2Zone1,
								withinZone:item.lite2Zone2,
								metro:item.lite2Zone3,
								restOfIndia:item.lite2Zone4,
								special:item.lite2Zone5,
								minCod:item.codRate,
								cod:item.codPcnt,
						})
						this.litetwoKg.push({
								carrier:`${this.modes[item.id]} add. 1kg`,
								withinCity:item.lite2OtherZone1,
								withinZone:item.lite2OtherZone2,
								metro:item.lite2OtherZone3,
								restOfIndia:item.lite2OtherZone4,
								special:item.lite2OtherZone5,
								minCod:item.codRate,
								cod:item.codPcnt
						})
					});

					// surface 5Kg Table
					res.data.billingPrices.filter(item => item.sf5Zone1 > 0).forEach((item, index) => {
						this.litefiveKg.push({
								carrier:`${this.modes[item.id]} 5Kg`,
								withinCity:item.sf5Zone1,
								withinZone:item.sf5Zone2,
								metro:item.sf5Zone3,
								restOfIndia:item.sf5Zone4,
								special:item.sf5Zone5,
								minCod:item.codRate,
								cod:item.codPcnt,
						})
						this.litefiveKg.push({
								carrier:`${this.modes[item.id]} add. 1kg`,
								withinCity:item.sf5OtherZone1,
								withinZone:item.sf5OtherZone2,
								metro:item.sf5OtherZone3,
								restOfIndia:item.sf5OtherZone4,
								special:item.sf5OtherZone5,
								minCod:item.codRate,
								cod:item.codPcnt
						})
					});

					// surface 10Kg Table
					res.data.billingPrices.filter(item => item.sfZone1 > 0).forEach((item, index) => {
						this.litetenKg.push({
								carrier:`${this.modes[item.id]} 10Kg`,
								withinCity:item.sfZone1,
								withinZone:item.sfZone2,
								metro:item.sfZone3,
								restOfIndia:item.sfZone4,
								special:item.sfZone5,
								minCod:item.codRate,
								cod:item.codPcnt,
						})
						this.litetenKg.push({
								carrier:`${this.modes[item.id]} add. 1kg`,
								withinCity:item.sfOtherZone1,
								withinZone:item.sfOtherZone2,
								metro:item.sfOtherZone3,
								restOfIndia:item.sfOtherZone4,
								special:item.sfOtherZone5,
								minCod:item.codRate,
								cod:item.codPcnt
						})
					});

					// surface 30Kg Table
					res.data.billingPrices.filter(item => item.sf30Zone1 > 0).forEach((item, index) => {
						this.surfacethirtykg.push({
								carrier:`${this.modes[item.id]} 30Kg`,
								withinCity:item.sf30Zone1,
								withinZone:item.sf30Zone2,
								metro:item.sf30Zone3,
								restOfIndia:item.sf30Zone4,
								special:item.sf30Zone5,
								minCod:item.codRate,
								cod:item.codPcnt,
						})
						this.surfacethirtykg.push({
								carrier:`${this.modes[item.id]} add. 1kg`,
								withinCity:item.sf30OtherZone1,
								withinZone:item.sf30OtherZone2,
								metro:item.sf30OtherZone3,
								restOfIndia:item.sf30OtherZone4,
								special:item.sf30OtherZone5,
								minCod:item.codRate,
								cod:item.codPcnt
						})
					});


				} else {
					// statement
					this.selectPlan = false
				}
			} catch(e) {
				// statements
				console.log(e);
				this.selectPlan = false
			}
		},
		async tableData(){
			this.isBusy = true
			const res = await this.axios.get('/choice/pricecalculator')
			if(res.data.planActivate) {
				this.planActivate = true
				this.rates = res.data.templates
				this.updateId = Object.assign({},res.data.currentPlan)
				this.rate = res.data.currentPlan.id
			}
			// airmode table
			this.airmodeItems = res.data.billingPrices.filter(item => item.airZone1 > 0).map((item) => {
				return {
					carrier:this.modes[item.id],
					withinCity:item.airZone1,
					withinZone:item.airZone2,
					metro:item.airZone3,
					restOfIndia:item.airZone4,
					special:item.airZone5,
					minCod:item.codRate,
					cod:item.codPcnt
				}
			});

			// lite 0.5Kg Table
		 	res.data.billingPrices.filter(item => item.liteHZone1 > 0).forEach((item, index) => {
				this.surfacehalfItems.push({
						carrier:`${this.modes[item.id]} 0.5Kg`,
						withinCity:item.liteHZone1,
						withinZone:item.liteHZone2,
						metro:item.liteHZone3,
						restOfIndia:item.liteHZone4,
						special:item.liteHZone5,
						minCod:item.codRate,
						cod:item.codPcnt,
				})
				// this.surfacehalfItems.push({
				// 		carrier:`${this.modes[item.id]} 0.5kg`,
				// 		withinCity:item.liteHOtherZone1,
				// 		withinZone:item.liteHOtherZone2,
				// 		metro:item.liteHOtherZone3,
				// 		restOfIndia:item.liteHOtherZone4,
				// 		special:item.liteHOtherZone5,
				// 		minCod:item.codRate,
				// 		cod:item.codPcnt
				// })
			});

			// lite 1Kg Table
			res.data.billingPrices.filter(item => item.lite1Zone1 > 0).forEach((item, index) => {

				this.liteoneKg.push({
						carrier:`${this.modes[item.id]} 1Kg`,
						withinCity:item.lite1Zone1,
						withinZone:item.lite1Zone2,
						metro:item.lite1Zone3,
						restOfIndia:item.lite1Zone4,
						special:item.lite1Zone5,
						minCod:item.codRate,
						cod:item.codPcnt,
				})
				this.liteoneKg.push({
						carrier:`${this.modes[item.id]} add. 1kg`,
						withinCity:item.lite1OtherZone1,
						withinZone:item.lite1OtherZone2,
						metro:item.lite1OtherZone3,
						restOfIndia:item.lite1OtherZone4,
						special:item.lite1OtherZone5,
						minCod:item.codRate,
						cod:item.codPcnt
				})
			});

			//lite 2Kg Table
			res.data.billingPrices.filter(item => item.lite2Zone1 > 0).forEach((item, index) => {
				this.litetwoKg.push({
						carrier:`${this.modes[item.id]} 2Kg`,
						withinCity:item.lite2Zone1,
						withinZone:item.lite2Zone2,
						metro:item.lite2Zone3,
						restOfIndia:item.lite2Zone4,
						special:item.lite2Zone5,
						minCod:item.codRate,
						cod:item.codPcnt,
				})
				this.litetwoKg.push({
						carrier:`${this.modes[item.id]} add. 1kg`,
						withinCity:item.lite2OtherZone1,
						withinZone:item.lite2OtherZone2,
						metro:item.lite2OtherZone3,
						restOfIndia:item.lite2OtherZone4,
						special:item.lite2OtherZone5,
						minCod:item.codRate,
						cod:item.codPcnt
				})
			});

			// surface 5Kg Table
			res.data.billingPrices.filter(item => item.sf5Zone1 > 0).forEach((item, index) => {
				this.litefiveKg.push({
						carrier:`${this.modes[item.id]} 5Kg`,
						withinCity:item.sf5Zone1,
						withinZone:item.sf5Zone2,
						metro:item.sf5Zone3,
						restOfIndia:item.sf5Zone4,
						special:item.sf5Zone5,
						minCod:item.codRate,
						cod:item.codPcnt,
				})
				this.litefiveKg.push({
						carrier:`${this.modes[item.id]} add. 1kg`,
						withinCity:item.sf5OtherZone1,
						withinZone:item.sf5OtherZone2,
						metro:item.sf5OtherZone3,
						restOfIndia:item.sf5OtherZone4,
						special:item.sf5OtherZone5,
						minCod:item.codRate,
						cod:item.codPcnt
				})
			});

			// surface 10Kg Table
			res.data.billingPrices.filter(item => item.sfZone1 > 0).forEach((item, index) => {
				this.litetenKg.push({
						carrier:`${this.modes[item.id]} 10Kg`,
						withinCity:item.sfZone1,
						withinZone:item.sfZone2,
						metro:item.sfZone3,
						restOfIndia:item.sfZone4,
						special:item.sfZone5,
						minCod:item.codRate,
						cod:item.codPcnt,
				})
				this.litetenKg.push({
						carrier:`${this.modes[item.id]} add. 1kg`,
						withinCity:item.sfOtherZone1,
						withinZone:item.sfOtherZone2,
						metro:item.sfOtherZone3,
						restOfIndia:item.sfOtherZone4,
						special:item.sfOtherZone5,
						minCod:item.codRate,
						cod:item.codPcnt
				})
			});

			// surface 30Kg Table
			res.data.billingPrices.filter(item => item.sf30Zone1 > 0).forEach((item, index) => {
				this.surfacethirtykg.push({
						carrier:`${this.modes[item.id]} 30Kg`,
						withinCity:item.sf30Zone1,
						withinZone:item.sf30Zone2,
						metro:item.sf30Zone3,
						restOfIndia:item.sf30Zone4,
						special:item.sf30Zone5,
						minCod:item.codRate,
						cod:item.codPcnt,
				})
				this.surfacethirtykg.push({
						carrier:`${this.modes[item.id]} add. 1kg`,
						withinCity:item.sf30OtherZone1,
						withinZone:item.sf30OtherZone2,
						metro:item.sf30OtherZone3,
						restOfIndia:item.sf30OtherZone4,
						special:item.sf30OtherZone5,
						minCod:item.codRate,
						cod:item.codPcnt
				})
			});
		}
	}
};
</script>

<style scoped type="text/scss">
	.b-table-sticky-header {
		max-height: max-content !important;
		overflow-y: auto;
		min-height: unset !important;
	}
	.check {
        padding: 0.1rem 0.5rem 0.1rem 0.5rem;
	    max-width: 8rem;
	    max-height: 4rem;
	    position: absolute;
	    bottom: 12.35rem;
		right: 7px;
	    z-index: 1;
	    background: #006eff;
	    border-radius: 10px;
	    color: white;
	}

	.current {
		background: #32b148;
	    color: white;
	    border-radius: 100px;
	    padding: 0.5rem;
	    width: 50%;
	    font-size: 0.875rem;
	}
	
	.fs19 {
		font-size: 1.3rem !important;
	}
</style>

<style lang="scss" scoped>
	.b-table-sticky-header {
		max-height: max-content !important;
		overflow-y: auto;
		min-height: unset !important;
	}

	.monthly {
		width: 103%
	}

	.Gold {
		border:3px solid #FFCD3C !important;
	}

	.gold {
		border:3px solid #FFCD3C !important;
	}

	.Silver {
		border:3px solid #FF8246 !important;
	}

	.silver {
		border:3px solid #FF8246 !important;		
	}

	.Bronze {
		border:3px solid #FA5A5A !important;
	}

	.bronze {
		border:3px solid #FA5A5A !important;
	}

	.Diamond {
		border:3px solid #64D778 !important;
	}

	.diamond {
		border:3px solid #64D778 !important;		
	}

	.Titanium {
		border:3px solid #A064E6 !important;
	}

	.titanium {
		border:3px solid #A064E6 !important;		
	}

	.Platinum {
		border:3px solid #2D374B !important;
	}

	.platinum {
		border:3px solid #2D374B !important;		
	}

	.plan_name {
	    color: white;
	    position: relative;
	    text-align: center;
	    background: #2d374b;
	    border-top-right-radius: 10px;
	    padding: 0.5rem 0rem;
	    border-top-left-radius: 10px;
	    border-width: 3px 3px 0 !important;
	    font-weight: 600;
	}

	.inputGroup {
	height: 13rem;
	background-color: #fff;
	display: block;
	position: relative;
	border-radius: 5px;
	font-size: 16px;

	label {
		padding: 18px 25px;
		width: 100%;
		display: block;
		text-align: center;
		font-size: 2.3rem;
		height: 100%;
		color: #3C454C;
		cursor: pointer;
		position: relative;
		border-radius:5px;
		transition: color 200ms ease-in;
		overflow: hidden;
		background: #e5f0ff;
		margin:unset

		&:before {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
			opacity: 0;
			z-index: -1;
		}
	}

	input:checked ~ label {
		height:15rem;
		background:white;

		&:before {
			transform: translate(-50%, -50%) scale3d(56, 56, 1);
			opacity: 1;
		}
	}

	input {
		width: 32px;
		height: 32px;
		order: 1;
		z-index: 2;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		visibility: hidden;
	}
}
.form {
	padding: 0 16px;
	margin: 50px auto;
	font-size: 18px;
	line-height: 36px;
	text-align: center;
}
legend.col-form-label{font-weight: bold!important}
.mb300{margin-bottom:320px}

</style>
