var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    ref: "calcpricing",
    attrs: {
      id: "modal-tall",
      size: "lg",
      title: "International Shipment",
      "hide-footer": "",
      okOnly: "",
      centered: "",
      "modal-class": "pricing-modal"
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
              _vm._v(" Price Calculator Chart ")
            ]),
            _c(
              "b-button",
              {
                attrs: { size: "sm", variant: "rounded" },
                on: {
                  click: function($event) {
                    return close()
                  }
                }
              },
              [_c("i", { staticClass: "icon-close-variant" })]
            )
          ]
        }
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "row justify-content-between pt-8 pt-md-26 pb-22"
              },
              [
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Pickup ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [_vm._v(" " + _vm._s(_vm.dialogHeader.originPin) + " ")]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" " + _vm._s(_vm.dialogHeader.orderType) + " ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/svg/arrow-right.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Delivery ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dialogHeader.customerPinCode) + " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet Weight ")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs14 lh20 text-center text-gray-900 mt-4 d-block"
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dialogHeader.packageWeight) + " Kg "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-6 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet Dimensions "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass: "icon-disclaimer fs12 text-gray-400",
                      attrs: { title: "Package LxBxH" }
                    })
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dialogHeader.packageLength) +
                          "cm x " +
                          _vm._s(_vm.dialogHeader.packageWidth) +
                          "cm x " +
                          _vm._s(_vm.dialogHeader.packageHeight) +
                          "cm "
                      )
                    ]
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "row pt-6 pt-md-22" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("b-table", {
                    staticClass: "package-details-table",
                    attrs: {
                      fields: _vm.fields,
                      "tbody-tr-class": _vm.tbodyRowClass,
                      responsive: "",
                      items: _vm.rowData,
                      "select-mode": "single"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(carrier)",
                        fn: function(data) {
                          return [
                            _c("b-img", {
                              staticClass: "mw100 carrier-logo",
                              attrs: { src: _vm.getImgUrl(data.item.carrier) }
                            })
                          ]
                        }
                      },
                      {
                        key: "cell(min_billing_weight)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " Min. Weight: " +
                                _vm._s(data.item.minweight) +
                                " kg "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(shipping_mode)",
                        fn: function(data) {
                          return [_vm._v(" " + _vm._s(data.item.mode) + " ")]
                        }
                      },
                      {
                        key: "cell(estimated_delivery)",
                        fn: function(data) {
                          return [
                            _vm._v(" " + _vm._s(data.item.tat) + " Days ")
                          ]
                        }
                      },
                      {
                        key: "cell(estimated_price)",
                        fn: function(data) {
                          return [_vm._v(" ₹" + _vm._s(data.item.price) + " ")]
                        }
                      },
                      {
                        key: "cell(show_details)",
                        fn: function(row) {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  size: "sm",
                                  variant: "icon-transparent"
                                },
                                on: { click: row.toggleDetails }
                              },
                              [_c("i", { staticClass: "icon-dropdown" })]
                            )
                          ]
                        }
                      },
                      {
                        key: "row-details",
                        fn: function(row) {
                          return [
                            _c(
                              "div",
                              { staticClass: "expanded-row" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs12 lh16 text-gray-800 pl-20 pb-8 d-block"
                                        },
                                        [_vm._v(" More details ")]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "8" } }, [
                                      _c("ul", [
                                        _c("li", [
                                          _c("span", [
                                            _vm._v(" Transit Time: "),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  row.item.details.transit_time
                                                )
                                              )
                                            ])
                                          ])
                                        ]),
                                        _c("li", [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.item.details.pod) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("li", [
                                          _c("span", [
                                            _vm._v(" Maximum Liability: "),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  row.item.details.max_liability
                                                )
                                              )
                                            ]),
                                            _vm._v(
                                              " or invoice value whichever is lower. "
                                            )
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "4" } },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pr-0 border-right"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column align-items-center"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "mw20 pb-8",
                                                      attrs: {
                                                        src: require("@/assets/svg/package-first.svg")
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-gray-600"
                                                      },
                                                      [_vm._v(" First 500g ")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        "₹" +
                                                          _vm._s(
                                                            row.item.details
                                                              .first_500
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              { staticClass: "pl-0" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column align-items-center"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "mw20 pb-8",
                                                      attrs: {
                                                        src: require("@/assets/svg/package-add.svg")
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-gray-600"
                                                      },
                                                      [_vm._v(" Add 500g ")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-gray-900"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " ₹" +
                                                            _vm._s(
                                                              row.item.details
                                                                .add_500
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.isOpen,
      callback: function($$v) {
        _vm.isOpen = $$v
      },
      expression: "isOpen"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }