var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AppTopbar", {
        attrs: {
          title: "International KYC",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "new"
        }
      }),
      _c("div", { staticClass: "container-fluid pt-md-100 pt-70" }, [
        _c("div", { staticClass: "card col-md-6 m-auto" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "card__content" },
            [
              _vm.isBusy
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-card",
                        [
                          _c("b-skeleton", {
                            staticClass: "mb-10",
                            attrs: { animation: "wave", width: "85%" }
                          }),
                          _c("b-skeleton", {
                            staticClass: "mb-10",
                            attrs: { animation: "wave", width: "55%" }
                          }),
                          _c("b-skeleton", {
                            staticClass: "mb-10",
                            attrs: { animation: "wave", width: "70%" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-form",
                    {
                      staticClass: "row pt-10",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.kycSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 col-12" },
                        [
                          _c("b-form-group", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var ariaDescribedby = ref.ariaDescribedby
                                  return [
                                    _c(
                                      "b-form-radio-group",
                                      {
                                        attrs: {
                                          id: "radio-group-2",
                                          "aria-describedby": ariaDescribedby,
                                          name: "radio-sub-component",
                                          disabled:
                                            _vm.user.isAdApproved == 1 ||
                                            _vm.user.isAdApproved == 2,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.customType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "customType",
                                              $$v
                                            )
                                          },
                                          expression: "form.customType"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-form-radio",
                                          {
                                            attrs: {
                                              value: "CSB IV",
                                              disabled:
                                                _vm.$store.getters.global
                                                  .csbRedirect ||
                                                _vm.$store.getters.global
                                                  .csbRejected
                                            }
                                          },
                                          [_vm._v("CSB IV")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          { attrs: { value: "CSB V" } },
                                          [_vm._v("CSB V")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "panCardId",
                                label: "PAN:",
                                "label-for": "panCard"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  disabled:
                                    _vm.user.isIntlPanApproved == 1 ||
                                    _vm.user.isIntlPanApproved == 2 ||
                                    _vm.user.panIsApproved == 1 ||
                                    _vm.user.panIsApproved == 2,
                                  id: "panCard",
                                  type: "text",
                                  placeholder: "Enter your pan card number",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.panNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "panNo", $$v)
                                  },
                                  expression: "form.panNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-6 col-9 d-flex align-items-center mt-10"
                        },
                        [
                          _c("b-form-file", {
                            ref: "panfile",
                            staticClass: "d-none",
                            attrs: {
                              accept: ".jpg, .png, .jpeg, .pdf",
                              plain: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.validate(_vm.form.panImage, "pan")
                              }
                            },
                            model: {
                              value: _vm.form.panImage,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "panImage", $$v)
                              },
                              expression: "form.panImage"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "w-100",
                              attrs: {
                                disabled:
                                  _vm.user.isIntlPanApproved == 1 ||
                                  _vm.user.isIntlPanApproved == 2,
                                variant: "tertiary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.panfile.$el.click()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.isIntlPanApproved == 1
                                      ? "PAN Approved"
                                      : _vm.user.isIntlPanApproved == 2
                                      ? "Pending Approval"
                                      : _vm.form.panImage
                                      ? _vm.form.panImage.name
                                      : _vm.form.customType == "CSB IV"
                                      ? "Choose File *"
                                      : "Choose File"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "GSTN:",
                                "label-for": "gstn"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.user.isIntlGstnApproved == 1 ||
                                    _vm.user.isIntlGstnApproved == 2 ||
                                    _vm.user.isGstnApproved == 1 ||
                                    _vm.user.isGstnApproved == 2,
                                  required: "",
                                  id: "gstn",
                                  type: "text",
                                  placeholder: "Enter your GST number"
                                },
                                model: {
                                  value: _vm.form.gstno,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "gstno", $$v)
                                  },
                                  expression: "form.gstno"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-6 col-9 d-flex align-items-center mt-10"
                        },
                        [
                          _c("b-form-file", {
                            ref: "gstfile",
                            staticClass: "d-none",
                            attrs: {
                              accept: ".jpg, .png, .jpeg, .pdf",
                              plain: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.validate(_vm.form.gstnImage, "gst")
                              }
                            },
                            model: {
                              value: _vm.form.gstnImage,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "gstnImage", $$v)
                              },
                              expression: "form.gstnImage"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "w-100",
                              attrs: {
                                disabled:
                                  _vm.user.isIntlGstnApproved == 1 ||
                                  _vm.user.isIntlGstnApproved == 2,
                                variant: "tertiary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.gstfile.$el.click()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.isIntlGstnApproved == 1
                                      ? "GST Approved"
                                      : _vm.user.isIntlGstnApproved == 2
                                      ? "Pending Approval"
                                      : _vm.form.gstnImage
                                      ? _vm.form.gstnImage.name
                                      : _vm.form.customType == "CSB V"
                                      ? "Choose File *"
                                      : "Choose File"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "IEC No:",
                                "label-for": "iecNo"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  disabled:
                                    _vm.user.isIecApproved == 1 ||
                                    _vm.user.isIecApproved == 2,
                                  maxlength: "10",
                                  pattern: "[a-zA-Z0-9]+",
                                  oninvalid:
                                    "this.setCustomValidity('IEC number has to be Alphanumeric and of 10 Characters')",
                                  oninput: "this.setCustomValidity('')",
                                  minlength: "10",
                                  id: "iecNo",
                                  type: "text",
                                  placeholder: "Enter your IEC number"
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value: _vm.form.iecNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "iecNo", $$v)
                                  },
                                  expression: "form.iecNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-6 col-9 d-flex align-items-center mt-10"
                        },
                        [
                          _c("b-form-file", {
                            ref: "iecfile",
                            staticClass: "d-none",
                            attrs: {
                              accept: ".jpg, .png, .jpeg, .pdf",
                              plain: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.validate(_vm.form.iecImage, "iec")
                              }
                            },
                            model: {
                              value: _vm.form.iecImage,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "iecImage", $$v)
                              },
                              expression: "form.iecImage"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "w-100",
                              attrs: {
                                disabled:
                                  _vm.user.isIecApproved == 1 ||
                                  _vm.user.isIecApproved == 2,
                                variant: "tertiary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.iecfile.$el.click()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.isIecApproved == 1
                                      ? "IEC Approved"
                                      : _vm.user.isIecApproved == 2
                                      ? "Pending Approval"
                                      : _vm.form.iecImage
                                      ? _vm.form.iecImage.name
                                      : _vm.form.customType == "CSB V"
                                      ? "Choose File *"
                                      : "Choose File"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "col-md-12",
                        staticStyle: {
                          "max-width": "97%",
                          "border-bottom": "2px solid #dedada",
                          margin: "1rem 0rem 1rem 0.5rem"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "Authorization Letter:",
                                "label-for": "authfile"
                              }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-20 btn-block",
                                  attrs: {
                                    disabled:
                                      _vm.user.authLetterApproved == 1 ||
                                      _vm.user.authLetterApproved == 2,
                                    id: "authfile",
                                    variant: "primary"
                                  },
                                  on: { click: _vm.downloadToast }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-download mr-11"
                                  }),
                                  _vm._v(" Download Template ")
                                ]
                              ),
                              _c("b-form-file", {
                                ref: "authfile",
                                staticClass: "d-none",
                                attrs: {
                                  accept: ".pdf, .doc, .docx",
                                  plain: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.validate(
                                      _vm.form.authfile,
                                      "auth"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.authfile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "authfile", $$v)
                                  },
                                  expression: "form.authfile"
                                }
                              }),
                              _c(
                                "b-button",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    disabled:
                                      _vm.user.authLetterApproved == 1 ||
                                      _vm.user.authLetterApproved == 2,
                                    variant: "tertiary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.authfile.$el.click()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.authLetterApproved == 1
                                          ? "Authorization Letter Approved"
                                          : _vm.user.authLetterApproved == 2
                                          ? "Pending Approval"
                                          : _vm.form.authfile
                                          ? _vm.form.authfile.name
                                          : "Choose File *"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 pt-2 fs12 text-gray-600" },
                        [
                          _c("strong", [
                            _vm._v(
                              "NOTE : Please put it on the Company's letter head duly stamped and signed by the authorized signatory."
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9 pt-15" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "date-field",
                                label: "LUT Expiry Date:",
                                "label-for": "date"
                              }
                            },
                            [
                              _c(
                                "date-picker",
                                {
                                  attrs: {
                                    placeholder: "yyyy-mm-dd",
                                    type: "date",
                                    "disabled-date": function(date) {
                                      return date < new Date()
                                    },
                                    "prefix-class": "xmx",
                                    disabled:
                                      _vm.user.louApproved == 1 ||
                                      _vm.user.louApproved == 2,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.lutExpiryDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "lutExpiryDate", $$v)
                                    },
                                    expression: "form.lutExpiryDate"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-calendar",
                                    attrs: { slot: "icon-calendar" },
                                    slot: "icon-calendar"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9 pt-15" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "Letter of Undertaking:",
                                "label-for": "iecNo"
                              }
                            },
                            [
                              _c("b-form-file", {
                                ref: "loufile",
                                staticClass: "d-none",
                                attrs: {
                                  accept: ".pdf, .doc, .docx",
                                  plain: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.validate(_vm.form.loufile, "lou")
                                  }
                                },
                                model: {
                                  value: _vm.form.loufile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "loufile", $$v)
                                  },
                                  expression: "form.loufile"
                                }
                              }),
                              _c(
                                "b-button",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    disabled:
                                      _vm.user.louApproved == 1 ||
                                      _vm.user.louApproved == 2,
                                    variant: "tertiary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.loufile.$el.click()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.louApproved == 1
                                          ? "Letter of Undertaking Approved"
                                          : _vm.user.louApproved == 2
                                          ? "Pending Approval"
                                          : _vm.form.loufile
                                          ? _vm.form.loufile.name
                                          : "Choose File"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "AD Code:",
                                "label-for": "adcode_intl"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.user.isAdApproved == 1 ||
                                    _vm.user.isAdApproved == 2,
                                  id: "adcode_intl",
                                  minlength: "7",
                                  maxlength: "15",
                                  type: "text",
                                  placeholder: "Enter AD Code"
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value: _vm.form.adCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "adCode", $$v)
                                  },
                                  expression: "form.adCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-9 pt-18" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: " ",
                                "label-for": "adcode_bank_intl"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.user.isAdApproved == 1 ||
                                    _vm.user.isAdApproved == 2,
                                  id: "adcode_bank_intl",
                                  minlength: "9",
                                  maxlength: "18",
                                  pattern: "[0-9]+",
                                  type: "text",
                                  title:
                                    "Enter the bank account number linked with AD code.",
                                  placeholder: "Enter Bank A/c No."
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value: _vm.form.adbankaccount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "adbankaccount", $$v)
                                  },
                                  expression: "form.adbankaccount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-6 col-9" }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-6 col-9 d-flex align-items-center"
                        },
                        [
                          _c("b-form-file", {
                            ref: "adcode",
                            staticClass: "d-none",
                            attrs: { accept: ".pdf, .doc, .docx", plain: "" },
                            on: {
                              input: function($event) {
                                return _vm.validate(_vm.form.adFile, "adCode")
                              }
                            },
                            model: {
                              value: _vm.form.adFile,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "adFile", $$v)
                              },
                              expression: "form.adFile"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "w-100",
                              attrs: {
                                disabled:
                                  _vm.user.isAdApproved == 1 ||
                                  _vm.user.isAdApproved == 2,
                                variant: "tertiary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.adcode.$el.click()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.isAdApproved == 1
                                      ? "AD Code Approved"
                                      : _vm.user.isAdApproved == 2
                                      ? "Pending Approval"
                                      : _vm.form.adFile
                                      ? _vm.form.adFile.name
                                      : _vm.form.customType == "CSB V"
                                      ? "Choose File *"
                                      : "Choose File"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !(
                                _vm.user.isIntlPanApproved &&
                                _vm.user.isIntlGstnApproved &&
                                _vm.user.isIecApproved &&
                                _vm.user.authLetterApproved &&
                                _vm.user.louApproved &&
                                _vm.user.isAdApproved
                              ),
                              expression:
                                "!(user.isIntlPanApproved && user.isIntlGstnApproved && user.isIecApproved && user.authLetterApproved && user.louApproved && user.isAdApproved)"
                            }
                          ],
                          staticClass: "col-12 text-gray-800 ml-10 pt-18",
                          attrs: {
                            id: "agreeCheckBox",
                            name: "checkbox-2",
                            value: "Checked",
                            "unchecked-value": "Not Checked"
                          },
                          model: {
                            value: _vm.form.kycAgree,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "kycAgree", $$v)
                            },
                            expression: "form.kycAgree"
                          }
                        },
                        [
                          _vm._v(
                            "By clicking Submit, you agree to Shyplite's "
                          ),
                          _c(
                            "b-link",
                            {
                              staticClass: "fs14",
                              attrs: {
                                href:
                                  _vm.user.seller &&
                                  _vm.user.seller.agreementData.agreement
                                    ? _vm.user.seller.agreementData.agreement
                                    : "https://shyplitepublicassets.s3.ap-southeast-1.amazonaws.com/intltnc1.pdf",
                                target: "_blank"
                              }
                            },
                            [_vm._v(" International T&C ")]
                          )
                        ],
                        1
                      ),
                      _vm.fileerror.length
                        ? _c(
                            "div",
                            { staticClass: "fs12 ml-10 text-red mt-20 w-100" },
                            [_vm._v(_vm._s(_vm.fileerror))]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-md-24 col" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !(
                                    _vm.user.isIntlPanApproved &&
                                    _vm.user.isIntlGstnApproved &&
                                    _vm.user.isIecApproved &&
                                    _vm.user.authLetterApproved &&
                                    _vm.user.louApproved &&
                                    _vm.user.isAdApproved
                                  ),
                                  expression:
                                    "!(user.isIntlPanApproved && user.isIntlGstnApproved && user.isIecApproved && user.authLetterApproved && user.louApproved && user.isAdApproved)"
                                }
                              ],
                              staticClass: "w-sm-100",
                              attrs: {
                                variant: "primary",
                                disabled:
                                  (_vm.form.kycAgree == "Checked"
                                    ? false
                                    : true) ||
                                  _vm.submitting ||
                                  (_vm.fileerror.length ? true : false) ||
                                  ((_vm.form.customType == "CSB IV" &&
                                  _vm.form.panImage &&
                                  _vm.form.authfile
                                    ? false
                                    : true) &&
                                    (_vm.form.customType == "CSB V" &&
                                    _vm.form.gstnImage &&
                                    _vm.form.gstno &&
                                    _vm.form.iecImage &&
                                    _vm.form.iecNo &&
                                    _vm.form.authfile &&
                                    _vm.form.adFile &&
                                    _vm.form.adCode &&
                                    _vm.form.adbankaccount
                                      ? false
                                      : true)),
                                type: "submit",
                                size: "lg"
                              }
                            },
                            [
                              _vm._v(" Submit"),
                              _vm.submitting
                                ? _c("b-spinner", { staticClass: "ml-10" })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 fw500  d-block" }, [
        _vm._v(" International KYC Form ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }