<template>
  <b-modal
    ref="calcpricing"
    id="modal-tall"
    size="lg"
    title="International Shipment"
    hide-footer
    okOnly
    v-model="isOpen"
    centered
    modal-class="pricing-modal"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block fs18 lh28 fw500 mt-8">
        Price Calculator Chart
      </span>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <template v-slot:default>
    <div class="row justify-content-between pt-8 pt-md-26 pb-22">
      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Pickup
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.originPin}}
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          {{ dialogHeader.orderType }}
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          <img src="@/assets/svg/arrow-right.svg" alt="" />
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Delivery
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.customerPinCode }}
        </span>
      </div>

      <div class="col-md-auto col-4 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet Weight
        </span>
        <span class="fs14 lh20 text-center text-gray-900 mt-4 d-block">
          {{ dialogHeader.packageWeight }} Kg
        </span>
      </div>

      <div class="col-md-auto col-6 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet Dimensions
          <i
            class="icon-disclaimer fs12 text-gray-400"
            v-b-tooltip.hover.right
            title="Package LxBxH"
          ></i>
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.packageLength }}cm x {{  dialogHeader.packageWidth }}cm x {{ dialogHeader.packageHeight }}cm
        </span>
      </div>
    </div>

    <div class="row pt-6 pt-md-22">
      <div class="col">
        <b-table
          
          :fields="fields"
          :tbody-tr-class="tbodyRowClass"
          responsive
          :items="rowData"
          class="package-details-table"
          select-mode="single"
        >
          <!-- <template v-slot:cell(selected)></template> -->

          <!-- A custom formatted column -->
          <template v-slot:cell(carrier)="data">
            <b-img :src="getImgUrl(data.item.carrier)" class="mw100 carrier-logo"></b-img>
          </template>

          <!-- A custom formatted column -->
          <template v-slot:cell(min_billing_weight)="data">
            Min. Weight: {{ data.item.minweight }} kg
          </template>

          <template v-slot:cell(shipping_mode)="data">
            {{ data.item.mode }}
          </template>

          <template v-slot:cell(estimated_delivery)="data">
            {{ data.item.tat }} Days
          </template>

          

          <!-- A custom formatted column -->
          <template v-slot:cell(estimated_price)="data">
            ₹{{ data.item.price }}
          </template>

          <template v-slot:cell(show_details)="row">
            <b-button
              size="sm"
              @click="row.toggleDetails"
              variant="icon-transparent"
              class="mr-2"
            >
              <i class="icon-dropdown"></i>
            </b-button>
          </template>

          <template v-slot:row-details="row">
            <div class="expanded-row">
              <b-row>
                <b-col>
                  <span class="fs12 lh16 text-gray-800 pl-20 pb-8 d-block">
                    More details
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <ul>
                    <li>
                      <span>
                        Transit Time:
                        <b>{{ row.item.details.transit_time }}</b>
                      </span>
                    </li>
                    <li>
                      <span>
                        {{ row.item.details.pod }}
                      </span>
                    </li>
                    <li>
                      <span>
                        Maximum Liability:
                        <b>{{ row.item.details.max_liability }}</b>
                        or invoice value whichever is lower.
                      </span>
                    </li>
                  </ul>
                </b-col>
                <b-col cols="4">
                  <b-row>
                    <b-col class="pr-0 border-right">
                      <div class="d-flex flex-column align-items-center">
                        <img
                          class="mw20 pb-8"
                          src="@/assets/svg/package-first.svg"
                        />
                        <span class="text-gray-600">
                          First 500g
                        </span>
                        <span>₹{{ row.item.details.first_500 }}</span>
                      </div>
                    </b-col>
                    <b-col class="pl-0">
                      <div class="d-flex flex-column align-items-center">
                        <img
                          class="mw20 pb-8"
                          src="@/assets/svg/package-add.svg"
                        />
                        <span class="text-gray-600">
                          Add 500g
                        </span>
                        <span class="text-gray-900">
                          ₹{{ row.item.details.add_500 }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "CalcPricing",
  props: ['modalOpen', 'formInfo', 'choiceData'],
  data() {
    return {
      selectedRow: null,
      isOpen: this.modalOpen,
      fields: [
        {
          key: "carrier",
          label: "Carrier",
          sortable: true,
          thClass: "pl-4 text-center",
          tdClass: "pl-4 text-center",
        },
        {
          key: "min_billing_weight",
          label: "Min. Billing Weight",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "shipping_mode",
          label: "Shipping Mode",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "estimated_delivery",
          label: "Est. Delivery",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "estimated_price",
          label: "Est. Price",
          thClass: "text-center",
          tdClass: "text-center",
        }
      ],

      // items: [
      //   {
      //     isActive: true,
      //     min_billing_weight: minweight,
      //     carrier: {
      //       name: "ups",
      //       logo_url: require("../assets/svg/ups_logo.svg"),
      //     },
      //     shipping_mode: "Surface 10kg",
      //     estimated_delivery: "1-2 days",
      //     estimated_price: 182.9,
      //     details: {
      //       transit_time: "5-10 days",
      //       pod: "On request, POD will be provided",
      //       max_liability: "USD 100",
      //       first_500: 1380,
      //       add_500: 400,
      //     },
      //   },
      // ],
      dialogHeader: {},
      rowData: [],
      form: {},
    };
  },

  watch: {

        formInfo: function(newValue) {
            if(newValue.originPin) {
                this.dialogData(newValue)
            }
        },

        choiceData: function(value) {
          if(Object.keys(value).length > 0) {
            this.choiceDialogData(value)
            this.getImgUrl();
          }
        },

        isOpen(newValue) {
          if(!newValue) {
            this.$emit('update:formInfo', null)
          }
        }


  },
  methods: {

    dialogData(form) {

        this.dialogHeader.originPin = form.originPin;
        this.dialogHeader.originCity = form.originCity;
        this.dialogHeader.orderType = form.orderType;

        this.dialogHeader.customerPinCode = form.customerPinCode;
        this.dialogHeader.customerCity = form.customerCity;

        this.dialogHeader.totalValue = form.totalValue;
        this.dialogHeader.packageWeight = form.packageWeight;

        this.dialogHeader.packageLength = form.packageLength;
        this.dialogHeader.packageWidth = form.packageWidth;

        this.dialogHeader.packageHeight = form.packageHeight;

        this.form = form;


    },
    choiceDialogData(value) {

        let enabledServiceArray = new Array();

        value.map(value => {
            if(value.length){
                value.map(val => {
                    enabledServiceArray.push(val);
                })
            }
        })

        this.rowData = enabledServiceArray.map((i) => {

            return {

                disabled:i.disabled,
                carrier: i.carrier,
                minweight: i.minweight,
                mode: this.staticName(this.$store.getters.modes, i.mode),
                price: i.price,
                service: i.service,
                serviceCode: i.serviceCode,
                tat: i.tat,
            }
        })

    },
    staticName(statics, index) {
      return statics.filter(i => i.value == index)[0].name
    },

    tbodyRowClass(item) {
        if (item.disabled) {
            return ["tdbg"];
        }
    },
    getImgUrl(carrier) {
      if(carrier) {
        var images = require(`../assets/images/${carrier}.png`);
        return images;
      }
    },
    setValue: function(value) {
      console.table(value);
    },
  },
  created: function() {
    this.$parent.$on("openModal", this.setValue);
  },
};
</script>

<style scoped>
 /deep/ .tdbg {
    opacity: 0.5;
  }
  .carrier-logo {
    max-height: 34px;
  }
</style>
