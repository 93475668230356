    <template>
      <div class="calculator">
        <AppTopbar
        title="Price Calculator"
        buttonText="New Order"
        buttonIconClass="icon-add"
        buttonLink="new"
        />

        <div class="container-fluid pt-70 pt-md-98" v-if="user.choice == 1">
          <b-form @submit.prevent="calPricing()" >
            <div class="card">
              <div class="card__title">
                <span class="fs18 lh24 fw500">
                  Order Information
              </span>
          </div>
          <div class="card__content overflow-visible">
            <div class="row">
              <div class="col-md-3">
                <b-form-group
                id="pickup-pincode-field"
                label="Pickup Pin Code"
                label-for="pickup-pin-code"
                >
                <b-form-input
                pattern="[0-9]+"
                required
                id="pickup-pin-code"
                v-model="form.originPin"
                @keyup="numberValidation($event.target.value,'pin1')"
                placeholder="Source Pin Code"
                :formatter="maxsix"
                type="number"
                :state="pincode"    
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group
          id="delivery-pincode-field"
          label="Delivery Pin Code"
          label-for="delivery-pin-code"
          >
          <b-form-input
          required
          pattern="[0-9]+"
          id="delivery-pin-code"
          v-model="form.customerPinCode"
          placeholder="Destination Pin Code"
          @keyup="numberValidation($event.target.value,'pin2')"
          :formatter="maxsix"
          type="number"
          :state="pincode1" 
          ></b-form-input>
      </b-form-group>
    </div>
    <div class="col-md-3">
        <b-form-group
        id="order-type-field"
        label="Order Type"
        label-for="order-type"
        >
        <multiselect
        id="order-type"
        v-model="form.orderType"
        :options="options"
        @input=selectType($event)
        :searchable="false"
        :show-labels="false"
        ></multiselect>
    </b-form-group>
    </div>
    </div>
    </div>
    <div class="card__title">
      <label
      for="orderId"
      v-b-tooltip.hover.right
      title="Information about dimensions!"
      class="fs18 lh24 fw500"
      >
      Package Information
      <i class="icon-disclaimer fs12 text-gray-400"></i>
    </label>
  </div>
    <div class="card__content">
      <div class="row">
        <div class="col-md-2 col-6">
          <b-form-group
          id="length-field"
          label="Length (cm)"
          label-for="length"
          >
          <b-form-input :min="this.$store.state.minD" :max="this.$store.state.maxD" required id="length" pattern="[0-9]+"
          v-model="form.packageLength" type="number" placeholder="0"></b-form-input>
      </b-form-group>
    </div>
    <div class="col-md-2 col-6">
        <b-form-group
        id="width-field"
        label="Width (cm)"
        label-for="width"
        >
        <b-form-input required id="width" :min="this.$store.state.minD" :max="this.$store.state.maxD" pattern="[0-9]+"
         v-model="form.packageWidth" type="number" placeholder="0"></b-form-input>
    </b-form-group>
    </div>
    <div class="col-md-2 col-6">
      <b-form-group
      id="height-field"
      label="Height (cm)"
      label-for="height"
      >
      <b-form-input required id="height" :min="this.$store.state.minD" :max="this.$store.state.maxD" pattern="[0-9]+"
      v-model="form.packageHeight" type="number" placeholder="0"></b-form-input>
    </b-form-group>
    </div>
    <div class="col-md-2 col-6">
        <div
        class="form-group wsnw" 
        id="weight-field"
        label="Weight (kg)"
        label-for="weight"
        >
        <label for="weight">Weight (kg)</label>
    <b-form-input required :min="this.$store.state.minW" :max="this.$store.state.maxW" id="weight" step=".01" pattern="[0-9]+"
    v-model="form.packageWeight" type="number" placeholder="0.00"></b-form-input>
    </div>
    </div>
    <div v-if="totalValue" class="col-md-2 col-6">
      <b-form-group
      id="height-field"
      label="Invoice Value"
      label-for="height"
      >
      <b-form-input required :min="this.$store.state.minD" :max="this.$store.state.maxI" id="height" step=".01" pattern="[0-9]+"
       v-model="form.totalValue" type="number" placeholder="0.00"></b-form-input>
    </b-form-group>
    </div>
    </div>
    <ul class="info-card sm">
      <li>
        <span>
          Quoted charges are all inclusive of 18% GST.
      </span>
    </li>
    <li>
        <span>
          This is courtesy rate, final bill may differ.
      </span>
    </li>
    </ul>

    <b-button :disabled="!(isComplete && (pincode || pincode == 'null') && (pincode1 || pincode1 == 'null')) || submitting" type="submit" variant="primary mt-20">
      <b-spinner v-if="submitting"></b-spinner>
      Get Pricing
    </b-button>

    </div>
    </div>
    </b-form>
    </div>


    <div class="container-fluid pt-70 pt-md-98" v-else>
          <b-form @submit.prevent="calTable()">
            <div class="card">
              <div class="card__title">
                <span class="fs18 lh24 fw500">
                  Order Information
              </span>
          </div>
          <div class="card__content overflow-visible">
            <div class="row">
              <div class="col-md-3">
                <b-form-group
                id="pickup-pincode-field"
                label="Pickup Pin Code"
                label-for="pickup-pin-code"
                >
                <b-form-input
                required
                pattern="[0-9]+"
                id="pickup-pin-code"
                v-model="form.sourcePin"
                @keyup="numberValidation($event.target.value,'pin1')"
                placeholder="Source Pin Code"
                :formatter="maxsix"
                type="number"
                :state="pincode"    
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group
          id="delivery-pincode-field"
          label="Delivery Pin Code"
          label-for="delivery-pin-code"
          >
          <b-form-input
          required
          pattern="[0-9]+"
          id="delivery-pin-code"
          v-model="form.destinationPin"
          placeholder="Destination Pin Code"
          @keyup="numberValidation($event.target.value,'pin2')"
          :formatter="maxsix"
          type="number"
          :state="pincode1" 
          ></b-form-input>
      </b-form-group>
    </div>
    <div class="col-md-3">
        <b-form-group
        id="order-type-field"
        label="Order Type"
        label-for="order-type"
        >
        <multiselect
        id="order-type"
        v-model="form.orderType2"
        :options="options"
        @input=selectType($event)
        :searchable="false"
        :show-labels="false"
        ></multiselect>
    </b-form-group>
    </div>
    </div>
    </div>
    <div class="card__title">
      <label
      for="orderId"
      v-b-tooltip.hover.right
      title="Information about dimensions!"
      class="fs18 lh24 fw500"
      >
      Package Information
      <i class="icon-disclaimer fs12 text-gray-400"></i>
    </label>
  </div>
    <div class="card__content">
      <div class="row">
        <div class="col-md-2 col-6">
          <b-form-group
          id="length-field"
          label="Length (cm)"
          label-for="length"
          >
          <b-form-input :min="this.$store.state.minD" :max="this.$store.state.maxD" required id="length" pattern="[0-9]+"
          v-model="form.length" type="number" placeholder="0"></b-form-input>
      </b-form-group>
    </div>
    <div class="col-md-2 col-6">
        <b-form-group
        id="width-field"
        label="Width (cm)"
        label-for="width"
        >
        <b-form-input required id="width" :min="this.$store.state.minD" :max="this.$store.state.maxD" pattern="[0-9]+"
         v-model="form.width" type="number" placeholder="0"></b-form-input>
    </b-form-group>
    </div>
    <div class="col-md-2 col-6">
      <b-form-group
      id="height-field"
      label="Height (cm)"
      label-for="height"
      >
      <b-form-input required id="height" :min="this.$store.state.minD" :max="this.$store.state.maxD" pattern="[0-9]+"
      v-model="form.height" type="number" placeholder="0"></b-form-input>
    </b-form-group>
    </div>
    <div class="col-md-2 col-6">
        <div
        class="form-group wsnw" 
        id="weight-field"
        label="Weight (kg)"
        label-for="weight"
        >
        <label for="weight">Weight (kg)</label>
    <b-form-input required :min="this.$store.state.minW" :max="this.$store.state.maxW" id="weight" step=".01" pattern="[0-9]+"
    v-model="form.weight" type="number" placeholder="0.00"></b-form-input>
    </div>
    </div>
    <div v-if="totalValue" class="col-md-2 col-6">
      <b-form-group
      id="height-field"
      label="Invoice Value"
      label-for="height"
      >
      <b-form-input required :min="this.$store.state.minD" step=".01" :max="this.$store.state.maxI" id="height" pattern="[0-9]+"
       v-model="form.invoice" type="number" placeholder="0.00"></b-form-input>
    </b-form-group>
    </div>
    </div>
    <ul class="info-card sm">
      <li>
        <span>
          Quoted charges are all inclusive of 18% GST.
      </span>
    </li>
    <li>
        <span>
          This is courtesy rate, final bill may differ.
      </span>
    </li>
    </ul>

    <b-button :disabled="!(isComplete && (pincode || pincode == 'null') && (pincode1 || pincode1 == 'null')) || autosubmitting" type="submit" variant="primary mt-20">
      <b-spinner v-if="autosubmitting"></b-spinner>
      Get Pricing
    </b-button>

    </div>
    </div>

    
    </b-form>
    </div>




    <CalculatorTables v-if="user.choice == 1" />
    <CalculatorTablesNC v-else  />
    <CalcPricing @openModal="openPricingModal" :formInfo.sync="calcForm" :choiceData.sync="calcData" ref="calcpricing" />
    <AutoCalcPricing @openModal="openAutoPricingModal" :formInfo.sync="autocalcForm" :serviceTab.sync="serviceTab" :pricingTab.sync="pricingTab" ref="autocalcpricing" />
    

    <div class="container-fluid pb-80 pb-md-0">
      <div class="card">
        <div class="card__content">
          <span class="fs18 lh24 fw500">
            Terms and Conditions
        </span>
        <ul class="info-card mt-20">
            <li>
              <span>
                Quoted charges are all inclusive of 18% GST.
            </span>
        </li>
        <li v-if="$store.getters.user.choice == 1">
          <span>
            For reverse order booking, the charges are equal to 2 times forward charges ( Reverse = 2 X Froward )
          </span>
        </li>
        <li>
          <span>
            Chargeable weight would be volumetric or actual weight,
            whichever is higher (Air/ Rush mode: LxBxH/ 5000 | LITE/ Surface
            mode: LxBxH/ 4000).
        </span>
    </li>
    <li>
      <span>
        Maximum liability for Forward shipping is Rs 5000 or Invoice
        value whichever is lower.
    </span>
    </li>
    <li>
      <span>
        Maximum liability for Reverse shipping is Rs 2000 or 50% of
        Invoice value whichever is lower.
    </span>
    </li>
    <li>
      <span>
        No Claim would be entertained for Glassware, Fragile products,
        Concealed damages and improper packaging.
    </span>
    </li>
    <li>
      <span>
        Manual label penalty is Rs 500 per shipment.
    </span>
    </li>
    <li>
      <span>
        Any weight dispute due to incorrect weight declaration cannot be
        claimed.
    </span>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </template>

    <script>
    import Multiselect from "vue-multiselect";
    import AppTopbar from "@/components/layout/AppTopbar.vue";
    import CalculatorTables from "@/components/CalculatorTables.vue";
    import CalculatorTablesNC from "@/components/CalculatorTablesNC.vue"
    import CalcPricing from "@/components/CalcPricing.vue";
    import AutoCalcPricing from "@/components/AutoCalcPricing.vue";
    import { mapState } from "vuex";

    export default {
      name: "Calculator",
      title: "Shyplite - Price Calculator",
      data() {
        return {
          orderType: null,
          options: ["Prepaid", "Cod", "Reverse"],
          form:{},
          calcData:[],
          calcForm:[],
          autocalcForm:[],
          pincode:null,
          pincode1:null,
          totalValue:false,
          serviceTab:[],
          pricingTab:[],
          NCtable:false,
          submitting : false,
          autosubmitting : false
      };
    },
    components: {
        AppTopbar,
        Multiselect,
        CalculatorTables,
        CalcPricing,
        CalculatorTablesNC,
        AutoCalcPricing
    },
    computed:{
        isComplete() {
            if(this.form.orderType || this.form.orderType2){return true} else {return false}
        },
        ...mapState(["user"])
    },
    methods: {
        selectType(value){
          if (value == 'Cod') {
            this.totalValue = true
          } else {
            this.totalValue = false
            this.form.totalValue = null
          }
        },
        maxsix(e){
            return String(e).substring(0, 6);
        },
        numberValidation(val,key) {
            if ((val || val == '') && key == 'pin1') {
                if(val.match(/^[0-9]{6}$/)){
                this.pincode = true
              } else if (val == '') {
                this.pincode = null
              } else{
                this.pincode = false
              }
            } else if((val || val == '') && key == 'pin2') {
                if(val.match(/^[0-9]{6}$/)){
                    this.pincode1 = true
                  } else if (val == '') {
                    this.pincode1 = null
                  } else {
                    this.pincode1 = false
                  }
            }
        },
        openPricingModal: function() {
          this.$refs.calcpricing.isOpen = !this.$refs
          .calcpricing.isOpen;
      },
      openAutoPricingModal() {
        this.$refs.autocalcpricing.isOpen = !this.$refs
          .autocalcpricing.isOpen;
      },
      async calPricing() {
        this.submitting = true
        let data = Object.assign({}, this.form);
        data.orderType = this.form.orderType.toLowerCase()
            try {
            	const res = await this.axios.post('/choice/getchoicedata',data)
                if (res.data.success) {
                    this.calcForm = this.form
                    this.calcData = res.data.choicepricing
                    this.submitting = false
                    this.openPricingModal();
                } else {
                    this.submitting = false
                    this.popToast("failed","Failed",`Service not available from ${this.form.originPin} to ${this.form.customerPinCode}. Please check the serviceability or enable other couriers.`);
                }
            } catch(e) {
                this.submitting = false
                this.popToast("failed","Failed","Data Fetching Failed!! Please Try Again");
            }
        
      },
      async calTable() {
        this.autosubmitting = true
        delete this.form.totalValue
        this.pricingTab = []
        this.serviceTab = []
        
        if (this.form.orderType2 == "Prepaid") {
          this.form['orderType'] = "1"
          this.form.invoice = "0"
        } else if (this.form.orderType2 == "Reverse") {
          this.form['orderType'] = "3"
          this.form.invoice = "0"
        } else {
          this.form['orderType'] = "2"
        }
        

        try {
          let data = Object.assign({}, this.form);
          const res = await this.axios.post('/settings/pricecalculator', data)

          this.autocalcForm = this.form
          this.autosubmitting = false
          this.openAutoPricingModal()
          for (let i in res.data.pricing) {

            if(i != 'surface-30kg') {
              this.pricingTab.push(res.data.pricing[i])
            }

          }

          for(let i in res.data.service) {

            if(i != 'surface-30kg') {
              this.serviceTab.push(res.data.service[i]);
            }
          }

          
        } catch(e) {
          this.autosubmitting = false
          console.log(e);
          this.NCtable = false
        }
      }
    },
    };
    </script>

    <style scoped>
      .tick {
            height: 1.5rem;
            width: 1.8rem;
      }

      .b-table-sticky-header > .table.b-table > thead > tr > th:first-child {
        background-color: #e7f0ff !important;
        color: #3F5579 !important;
        font-weight: bold !important;
        font-size: 11px !important;
        text-align: center !important;
      }

      .b-table-sticky-header {
          max-height: 1000px !important;
          overflow-y: auto;
        }

        .b-table-sticky-header > .table.b-table > thead > tr > th  {
          font-weight: bold !important;
            background-color: #3f5579c7 !important;
            color: #FFF !important;
            border-bottom: 1px solid #ccc !important;
            border-right: 1px solid #ccc !important;
            text-align: center !important;
        }

        .b-table-sticky-header > .table.b-table > tbody > tr > .row-head {
          font-weight: bold !important;
            background-color: #3f5579c7 !important;
            color: #FFF !important;
            border-left: 1px solid #ccc !important;
            border-right: 1px solid #ccc !important;
            text-align: center !important;
        }

        .b-table-sticky-header > .table.b-table > tbody .sub-row-head {
              background-color: #3f5579c7 !important;
              color: #FFF !important;
              font-weight: bold !important;
              text-align: center !important;
        }

        .b-table-sticky-header > .table.b-table > tbody {
          text-align: center !important;
        }
    </style>