var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    ref: "autocalcpricing",
    attrs: {
      id: "modal-tall",
      size: "lg",
      title: "International Shipment",
      "hide-footer": "",
      okOnly: "",
      centered: "",
      "modal-class": "pricing-modal"
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
              _vm._v(" Price Calculator Chart ")
            ]),
            _c(
              "b-button",
              {
                attrs: { size: "sm", variant: "rounded" },
                on: {
                  click: function($event) {
                    return close()
                  }
                }
              },
              [_c("i", { staticClass: "icon-close-variant" })]
            )
          ]
        }
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "row justify-content-between pt-8 pt-md-26 pb-22"
              },
              [
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Pickup ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [_vm._v(" " + _vm._s(_vm.dialogHeader.originPin) + " ")]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" " + _vm._s(_vm.dialogHeader.orderType) + " ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/svg/arrow-right.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Delivery ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dialogHeader.customerPinCode) + " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet Weight ")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs14 lh20 text-center text-gray-900 mt-4 d-block"
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dialogHeader.packageWeight) + " Kg "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-12 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet dimensions "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass: "icon-disclaimer fs12 text-gray-400",
                      attrs: { title: "Information about dimensions!" }
                    })
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dialogHeader.packageLength) +
                          "cm x " +
                          _vm._s(_vm.dialogHeader.packageWidth) +
                          "cm x " +
                          _vm._s(_vm.dialogHeader.packageHeight) +
                          "cm "
                      )
                    ]
                  )
                ])
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "b-table-simple",
                  {
                    staticClass: "mt-50 price-calculator-table",
                    attrs: {
                      "sticky-header": "",
                      "no-border-collapse": "",
                      id: "air-mode"
                    }
                  },
                  [
                    _c(
                      "b-thead",
                      { attrs: { "head-variant": "light" } },
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-th", { staticClass: "prepaid" }, [
                              _vm._v("PREPAID")
                            ]),
                            _c("b-th", [_vm._v("Air")]),
                            _c("b-th", [_vm._v("Surface-10kg")]),
                            _c("b-th", [_vm._v("Surface-5kg")]),
                            _c("b-th", [_vm._v("Lite-2kg")]),
                            _c("b-th", [_vm._v("Lite-1kg")]),
                            _c("b-th", [_vm._v("Lite-0.5kg")])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v(" Service ")
                            ]),
                            _vm._l(_vm.service, function(item, index) {
                              return _c("b-td", { key: index }, [
                                item
                                  ? _c("img", {
                                      staticClass: "tick",
                                      attrs: {
                                        src: require("@/assets/images/yes.png")
                                      }
                                    })
                                  : _vm._e(),
                                !item
                                  ? _c("img", {
                                      staticClass: "tick",
                                      attrs: {
                                        src: require("@/assets/images/no.png")
                                      }
                                    })
                                  : _vm._e()
                              ])
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-tr",
                          [
                            _c("b-td", { staticClass: "sub-row-head" }, [
                              _vm._v(" Pricing ")
                            ]),
                            _vm._l(_vm.pricing, function(item, index) {
                              return _c("b-td", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.isOpen,
      callback: function($$v) {
        _vm.isOpen = $$v
      },
      expression: "isOpen"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }